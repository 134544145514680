import React, { FC, useCallback, useRef } from 'react';
import { NotificationStatus } from '../../Notifications/Notifications.types';
import {
  InboxAvatar,
  InboxAvatarAssets,
  InboxAvatarPlaceholder,
  InboxItemContainer,
  InboxSubstanceImage,
} from './InboxMentionItem.styled';
import {
  AvatarMode,
  StyledAvatar,
  StyledImg,
} from '../../User/UserAvatar/Avatar.styled';
import { getGlideImageSrcSet } from '../../../shared/utils/url.utils';
import { UserIcon } from '../../../shared/icons';
import { NotificationObserver } from '../../Notifications/Notification/NotificationObserver';
import { InboxMentionItemMessage } from './InboxMentionItemMessage';
import { useInbox, useMarkNotificationAsReadMutation } from '../Inbox.hooks';
import { MentionsApiType } from '../Inbox.types';
import { useMobile } from '../../../shared/hooks';

const AVATAR_IMAGE_SIZE = 32;
const SUBSTANCE_IMAGE_SIZE = 24;

interface InboxItemProps {
  item: MentionsApiType;
}

export const InboxMentionItem: FC<InboxItemProps> = React.memo(({ item }) => {
  const { message, status } = item;
  const readRequestSent = useRef(false);
  const inboxContext = useInbox();
  const isMobile = useMobile();
  const [markMentionAsReadMutation] = useMarkNotificationAsReadMutation();
  const markAsRead = useCallback(() => {
    if (readRequestSent.current) {
      isMobile && inboxContext.closeInbox();
      return;
    }
    readRequestSent.current = true;

    markMentionAsReadMutation({
      variables: {
        input: {
          id: item.id,
          markAsRead: true,
        },
      },
    })
      .then(() => isMobile && inboxContext.closeInbox())
      .catch(() => {
        readRequestSent.current = false;
      });
    readRequestSent.current = true;
  }, [inboxContext, isMobile, item.id, markMentionAsReadMutation]);

  return (
    <div>
      {status === NotificationStatus.NEW && (
        <NotificationObserver onMarkAsRead={markAsRead} />
      )}
      <InboxItemContainer data-testid="mention-item">
        <InboxAvatar>
          <InboxAvatarAssets>
            <StyledAvatar size={AVATAR_IMAGE_SIZE} mode={AvatarMode.circle}>
              {message.fields.notification_image ? (
                <StyledImg
                  src={message.fields.notification_image}
                  srcSet={getGlideImageSrcSet(
                    message.fields.notification_image,
                    {
                      w: AVATAR_IMAGE_SIZE,
                      h: AVATAR_IMAGE_SIZE,
                      fit: 'crop-center',
                    },
                  )}
                  size={AVATAR_IMAGE_SIZE}
                  alt={`${message.fields.account_first_name} ${message.fields.account_last_name}`}
                  initials={''}
                  data-testid="notification-image"
                />
              ) : (
                <InboxAvatarPlaceholder data-testid="person-icon">
                  <UserIcon />
                </InboxAvatarPlaceholder>
              )}
            </StyledAvatar>
            {message.fields.context_image && (
              <InboxSubstanceImage>
                <StyledAvatar
                  size={SUBSTANCE_IMAGE_SIZE}
                  mode={AvatarMode.circle}>
                  <StyledImg
                    src={message.fields.context_image}
                    srcSet={getGlideImageSrcSet(message.fields.context_image, {
                      w: SUBSTANCE_IMAGE_SIZE,
                      h: SUBSTANCE_IMAGE_SIZE,
                      fit: 'crop-center',
                    })}
                    size={SUBSTANCE_IMAGE_SIZE}
                    alt={`${message.fields.account_first_name} ${message.fields.account_last_name}`}
                    initials={''}
                    data-testid="context-image"
                  />
                </StyledAvatar>
              </InboxSubstanceImage>
            )}
          </InboxAvatarAssets>
        </InboxAvatar>
        <InboxMentionItemMessage item={item} />
      </InboxItemContainer>
    </div>
  );
});
