import React, { FC, useCallback } from 'react';
import {
  NotificationMessageUserChatMentionedFields,
  NotificationStatus,
} from '../../Notifications/Notifications.types';
import { combineFirstAndLastNames } from '../../Notifications/Notifications.utils';
import {
  InboxContent,
  InboxContentStrong,
  InboxCreatedAt,
  StyledInboxStatus,
} from './InboxMentionItem.styled';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { InboxTranslation } from '../i18n';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../Workspace/Workspace.hooks';
import { DateLocales } from '../../Intl/i18n/i18n.dates';
import { RichMessage } from '../../Chat/ChatView/ConversationView/ChatMessage/RichMessage';
import { useConversationStateControls } from '../../Chat/ChatView/ConversationState/ConversationState.hooks';
import { getChatMessageIri } from '../../Chat/Chat.utils';
import { MentionsApiType } from '../Inbox.types';
import { useInbox } from '../Inbox.hooks';
import { useQueryParams } from '../../../shared/hooks';
import { useNavigate } from 'react-router';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { LinkDetailsTab } from '../../Link/Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useSearchMessageData } from '../../Chat/Chat.hooks';
import { useOpenThreadSidebar } from '../../Chat/ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.hooks';
import { getShortId } from '../../../shared/utils/id';

export const InboxMentionItemMessage: FC<{ item: MentionsApiType }> = ({
  item,
}) => {
  const { message, createdAt } = item;
  const { conversation_id, message_id } = message.fields;
  const { workspace } = useCurrentWorkspace();
  const { locale, formatMessage } = useIntl();
  const dateFnsLocale = DateLocales[locale];
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { openThreadSidebar } = useOpenThreadSidebar();

  const mentionFields =
    message.fields as NotificationMessageUserChatMentionedFields;
  const firstName = mentionFields.account_first_name;
  const lastName = mentionFields.account_last_name;
  const { mobileNavigationSidebarToggle, mobileNavigationSidebarIsOpen } =
    useMobileNavigationSidebar();

  const { setState } = useConversationStateControls();

  const inboxContext = useInbox();

  const { setSearchMessage } = useSearchMessageData();

  const setConversationState = useCallback(() => {
    if (mobileNavigationSidebarIsOpen) {
      mobileNavigationSidebarToggle();
    }

    inboxContext.closeInbox();

    if (!message.fields.parent_message_id) {
      return;
    }

    setState({
      openThreadOfMessageId: getChatMessageIri(
        message.fields.parent_message_id,
      ),
      scrollToMessageId: getChatMessageIri(message.fields.message_id),
    });
  }, [
    inboxContext,
    message.fields.message_id,
    message.fields.parent_message_id,
    mobileNavigationSidebarIsOpen,
    mobileNavigationSidebarToggle,
    setState,
  ]);

  const inboxContentClickHandler = useCallback(
    async (event: React.MouseEvent) => {
      setConversationState();

      if (
        message.fields.parent_message_id &&
        message.fields.parent_message_created_at &&
        message.fields.conversation_id
      ) {
        openThreadSidebar(
          getChatMessageIri(message.fields.parent_message_id),
          message.fields.conversation_id,
          message_id,
          true,
        );

        return;
      }

      setSearchMessage(
        message.fields.parent_message_id &&
          message.fields.parent_message_created_at
          ? {
              id: getChatMessageIri(message.fields.parent_message_id),
              createdAt: message.fields.parent_message_created_at,
              isThreadMessage: true,
            }
          : {
              id: getChatMessageIri(message_id),
              createdAt: createdAt,
            },
      );

      if (message.fields.link_id) {
        event.preventDefault();
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            linkDetails: message.fields.link_id,
            linkDetailsTab: LinkDetailsTab.COMMENTS,
          }),
        });
      }
    },
    [
      setConversationState,
      message.fields.parent_message_id,
      message.fields.parent_message_created_at,
      message.fields.conversation_id,
      message.fields.link_id,
      setSearchMessage,
      message_id,
      createdAt,
      openThreadSidebar,
      navigate,
      queryParams,
    ],
  );

  return (
    <InboxContent
      data-testid="content"
      onClick={inboxContentClickHandler}
      to={`/workspace/${getShortId(workspace.id)}/chats/${conversation_id}`}
      title={combineFirstAndLastNames(firstName, lastName) || ''}>
      <div>
        <InboxContentStrong data-testid="mention-author">
          {combineFirstAndLastNames(firstName || lastName, null)}
        </InboxContentStrong>
        <InboxCreatedAt
          title={format(parseISO(item?.createdAt), 'PPpp', {
            locale: dateFnsLocale,
          })}
          data-testid="mention-created-at">
          {formatDistanceToNow(parseISO(item?.createdAt))}
        </InboxCreatedAt>
      </div>
      <div className="inbox-message" data-testid="inbox-mention-message">
        <div>
          <RichMessage children={message.fields.message_text} />
        </div>
        <div>
          {item.status === NotificationStatus.NEW && (
            <StyledInboxStatus
              title={formatMessage({ id: InboxTranslation.unread })}
              data-testid="unread"
            />
          )}
        </div>
      </div>
    </InboxContent>
  );
};
