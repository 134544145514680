import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../shared/components/Input';
import { FormBrowserSelect } from '../../../../shared/components/Select/FormBrowserSelect';
import {
  FieldSet,
  FieldSetControls,
} from '../../../../shared/components/FieldSet';
import { BillingTranslation } from '../../i18n';
import { COUNTRIES, countryVatOptions } from '../../Billing.constants';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  BillingContactFormValues,
  PaymentCustomerApiType,
} from '../../Billing.types';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import {
  CountryFieldBlock,
  FieldsGroup,
} from './EditBillingInformationForm.styled';

const BillingContactSchema: Schema<BillingContactFormValues> =
  Yup.object().shape(
    {
      billingName: Yup.string().required(
        BillingTranslation.formBillingNameRequired,
      ),
      country: Yup.string(),
      city: Yup.string().required(BillingTranslation.formCityRequired),
      line1: Yup.string().required(BillingTranslation.formLine1Required),
      line2: Yup.string(),
      postalCode: Yup.string().required(
        BillingTranslation.formPostCodeRequired,
      ),
      state: Yup.string(),

      vatType: Yup.string().when('vatNumber', {
        is: vatNumber => vatNumber?.length > 0,
        then: Yup.string().required(BillingTranslation.formVatTypeRequired),
      }),
      vatNumber: Yup.string().when('vatType', {
        is: vatType => vatType?.length > 0,
        then: Yup.string().required(BillingTranslation.formVatNumberRequired),
      }),
    },
    [['vatType', 'vatNumber']],
  );

interface EditBillingInformationFormProps {
  onSubmit: (data: BillingContactFormValues) => void;
  onCancel: () => void;
  paymentCustomer: PaymentCustomerApiType;
}

export const EditBillingInformationForm: FC<
  EditBillingInformationFormProps
> = ({ onSubmit, onCancel, paymentCustomer }) => {
  const { formatMessage } = useIntl();

  const initialValues = useMemo(() => {
    return {
      id: paymentCustomer?.id,
      billingName: paymentCustomer?.billingName,
      country: paymentCustomer?.country,
      city: paymentCustomer?.city,
      line1: paymentCustomer?.line1,
      line2: paymentCustomer?.line2,
      postalCode: paymentCustomer?.postalCode,
      state: paymentCustomer?.state,
      vatType: paymentCustomer?.vatType || '',
      vatNumber: paymentCustomer?.vatNumber || '',
    };
  }, [paymentCustomer]);

  const countriesList = useMemo(
    () =>
      Object.keys(COUNTRIES).map(key => (
        <option key={key} value={key}>
          {COUNTRIES[key]}
        </option>
      )),
    [],
  );

  const taxIdOptions = useMemo(() => {
    return countryVatOptions.map(({ title, value }, i) => (
      <option key={value + i} value={value}>
        {title}
      </option>
    ));
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BillingContactSchema}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <FieldSet disabled={isSubmitting}>
            <Field
              name="billingName"
              label={formatMessage({
                id: BillingTranslation.formBillingNameLabel,
              })}
              component={FormInput}
            />
            <Field
              name="line1"
              type="text"
              data-testid="line1-input"
              label={formatMessage({
                id: BillingTranslation.formLine1Label,
              })}
              component={FormInput}
            />
            <Field
              name="line2"
              type="text"
              data-testid="line2-input"
              label={formatMessage({
                id: BillingTranslation.formLine2Label,
              })}
              component={FormInput}
            />
            {/* TODO: This will be renamed to postal_code to match stripe format */}
            <Field
              name="postalCode"
              type="text"
              data-testid="postalCode-input"
              label={formatMessage({
                id: BillingTranslation.formPostCodeLabel,
              })}
              component={FormInput}
            />
            <Field
              name="city"
              type="text"
              label={formatMessage({
                id: BillingTranslation.formCityLabel,
              })}
              component={FormInput}
            />
            <Field
              name="state"
              type="text"
              data-testid="state-input"
              label={formatMessage({
                id: BillingTranslation.formStateLabel,
              })}
              component={FormInput}
            />
            <CountryFieldBlock>
              <Field
                className="countryIdType"
                name="country"
                label={formatMessage({
                  id: BillingTranslation.formCountryLabel,
                })}
                component={FormBrowserSelect}>
                {countriesList}
              </Field>
            </CountryFieldBlock>

            <FieldsGroup>
              <Field
                className="taxIdType"
                name="vatType"
                label={formatMessage({
                  id: BillingTranslation.formVatType,
                })}
                component={FormBrowserSelect}>
                <option value={''} />
                {taxIdOptions}
              </Field>

              <Field
                className="vatIdNumber"
                name="vatNumber"
                type="text"
                label={formatMessage({
                  id: BillingTranslation.formVatNumber,
                })}
                л
                component={FormInput}
              />
            </FieldsGroup>
          </FieldSet>

          {/*TODO: Test button state based on field state*/}
          <FieldSetControls>
            <Button
              type="button"
              mode={ButtonMode.secondary}
              onClick={onCancel}
              size={ButtonSize.sm}
              disabled={isSubmitting}
              data-testid="cancel">
              <FormattedMessage id={BillingTranslation.formEditCancelButton} />
            </Button>
            <Button
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.sm}
              disabled={isSubmitting || !dirty}
              data-testid="submit">
              <FormattedMessage id={BillingTranslation.formEditSubmitButton} />
            </Button>
          </FieldSetControls>
          {isSubmitting && <SpinnerFullscreen />}
        </Form>
      )}
    </Formik>
  );
};
