import React, { FC, useCallback, useEffect, useState } from 'react';
import { useQueryParams } from '../../../../shared/hooks';
import { useAuthService, useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { LinkAccountSuccessScreen } from './LinkAccountSuccessScreen';
import { LinkAccountPreview } from './LinkAccountPreview';
import { Spinner } from '../../../../shared/components/Spinner';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import {
  CompleteLinkAccountControls,
  CompleteLinkAccountData,
} from './CompleteLinkAccountPage.styled';
import {
  useAccountLinkRequestQuery,
  useCancelLinkRequestForAccountMutation,
  useCompleteAccountLinkRequestMutation,
} from '../../Onboarding.hooks';

const REDIRECT_TIMEOUT = 4000;

export const CompleteLinkAccountPage: FC = () => {
  const queryParams = useQueryParams();
  const { linkRequestId } = queryParams;
  const navigate = useNavigate();
  const authService = useAuthService();

  const { account, refetchAccountData } = useCurrentAccount();

  const [controlButtonsDisabled, setControlButtonsDisabled] = useState(false);

  const { data, loading, refetch } = useAccountLinkRequestQuery({
    skip: !linkRequestId,
    variables: {
      id: linkRequestId as string,
    },
    fetchPolicy: 'no-cache',
  });

  const [completeAccountLinkRequestMutation] =
    useCompleteAccountLinkRequestMutation();

  const handleCompleteLinkAccountClick = useCallback(() => {
    setControlButtonsDisabled(true);

    return completeAccountLinkRequestMutation({
      variables: {
        input: {
          id: linkRequestId,
        },
      },
    })
      .then(() => {
        if (refetchAccountData) {
          refetchAccountData();
          refetch();
        }
        authService?.getToken({ ignoreCache: true });
      })
      .catch(e => {
        setControlButtonsDisabled(false);
        showToastGraphQLErrors(e.graphQLErrors || e.errors);
      });
  }, [
    completeAccountLinkRequestMutation,
    linkRequestId,
    refetchAccountData,
    refetch,
    authService,
  ]);

  const [cancelLinkRequestForAccountMutation] =
    useCancelLinkRequestForAccountMutation();
  const handleCancelLinkAccountClick = useCallback(() => {
    if (!account?.pendingLinkRequestId) {
      navigate('/');
      return;
    }

    setControlButtonsDisabled(true);
    return cancelLinkRequestForAccountMutation()
      .then(() => {
        if (refetchAccountData) {
          refetchAccountData();
          navigate('/');
        }
        showToastSuccessMessage(
          OnboardingTranslation.onboardingLinkAccountCancelMessage,
        );
      })
      .catch(e => {
        setControlButtonsDisabled(false);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    cancelLinkRequestForAccountMutation,
    refetchAccountData,
    account,
    navigate,
  ]);

  useEffect(() => {
    if (data?.accountLinkRequest.linked) {
      const timer = setTimeout(() => {
        navigate('/');
      }, REDIRECT_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [navigate, data]);

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="link-account-step">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingLinkAccountHeader}
          />
        </OnboardingStepHeader>

        {data?.accountLinkRequest.linked ? (
          <LinkAccountSuccessScreen />
        ) : (
          <>
            {loading ? (
              <Spinner containerHeight={100} />
            ) : (
              <>
                <CompleteLinkAccountData>
                  {data?.accountLinkRequest.mainAccount && (
                    <LinkAccountPreview
                      account={data.accountLinkRequest.mainAccount}
                    />
                  )}
                </CompleteLinkAccountData>
                <CompleteLinkAccountControls>
                  <Button
                    size={ButtonSize.md}
                    mode={ButtonMode.primary}
                    onClick={handleCompleteLinkAccountClick}
                    disabled={controlButtonsDisabled}
                    fullWidth>
                    <FormattedMessage
                      id={OnboardingTranslation.onboardingLinkAccountLinkButton}
                    />
                  </Button>
                  <Button
                    size={ButtonSize.md}
                    mode={ButtonMode.secondary}
                    onClick={handleCancelLinkAccountClick}
                    disabled={controlButtonsDisabled}
                    fullWidth>
                    <FormattedMessage
                      id={
                        OnboardingTranslation.onboardingLinkAccountCancelButton
                      }
                    />
                  </Button>
                </CompleteLinkAccountControls>
              </>
            )}
          </>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
