import styled from 'styled-components';

export const StyledBrowserSelect = styled.div<{}>`
  position: relative;

  select,
  button {
    appearance: none;
    background-color: ${p => p.theme.colors.Surface};
    border: 1px solid ${p => p.theme.colors.BorderDefault};
    border-radius: 4px;
    padding: 0.625rem 2.25rem 0.625rem 1rem;
    width: 100%;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.Regular};
    line-height: 1.25;
    cursor: pointer;
    text-align: left;

    &[disabled] {
      background-color: ${p => p.theme.colors.SurfaceSubdued};
      cursor: not-allowed;
    }
  }

  svg {
    position: absolute;
    pointer-events: none;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;
