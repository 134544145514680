import React, { FC, useCallback, useRef } from 'react';
import { ThreadNotificationApiType } from '../Inbox.types';
import {
  AuthorName,
  BodyContainer,
  ImageContainer,
  MessageText,
  ParentMessage,
  ParentMessageContainer,
  StyledInboxThreadItem,
  TopLeftCorner,
  UnreadBadge,
} from './InboxThreadItem.styled';
import { ChatAvatar } from '../../Chat/ChatAvatar';
import { useAccountsContext } from '../../Account';
import { getAccountName } from '../../User/User.utils';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { InboxCreatedAt } from '../InboxMentionItem/InboxMentionItem.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateLocales } from '../../Intl/i18n/i18n.dates';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { NotificationStatus } from '../../Notifications/Notifications.types';
import { useInbox, useMarkNotificationAsReadMutation } from '../Inbox.hooks';
import { NotificationObserver } from '../../Notifications/Notification/NotificationObserver';
import { RichMessage } from '../../Chat/ChatView/ConversationView/ChatMessage/RichMessage';
import { InboxTranslation } from '../i18n';
import { getAccountId } from '../../Auth/Auth.utils';
import { useConversationStateControls } from '../../Chat/ChatView/ConversationState/ConversationState.hooks';
import { getChatMessageIri } from '../../Chat/Chat.utils';
import { ChatTranslation } from '../../Chat/i18n';
import { useSearchMessageData } from '../../Chat/Chat.hooks';
import { useOpenThreadSidebar } from '../../Chat/ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.hooks';
import { getShortId } from '../../../shared/utils/id';

export const InboxThreadItem: FC<{ item: ThreadNotificationApiType }> = ({
  item,
}) => {
  const { workspace } = useCurrentWorkspace();
  const inboxContext = useInbox();

  const { formatMessage, locale } = useIntl();
  const dateFnsLocale = DateLocales[locale];

  const { accountsWithAvailability } = useAccountsContext();
  const messageAuthor =
    accountsWithAvailability[getAccountId(item.message.fields.account_id)];
  const parentMessageAuthor =
    accountsWithAvailability[
      getAccountId(item.message.fields.parent_message_account_id)
    ];
  const { setSearchMessage } = useSearchMessageData();
  const { openThreadSidebar } = useOpenThreadSidebar();

  const readRequestSent = useRef(false);
  const [markThreadAsReadMutation] = useMarkNotificationAsReadMutation();
  const markAsRead = useCallback(() => {
    console.log('markAsRead thread notification');

    console.log('readRequestSent', readRequestSent.current);
    if (readRequestSent.current) {
      return;
    }

    readRequestSent.current = true;

    markThreadAsReadMutation({
      variables: {
        input: {
          id: item.id,
          markAsRead: true,
        },
      },
    }).catch(() => {
      readRequestSent.current = false;
    });
  }, [item.id, markThreadAsReadMutation]);

  const { setState } = useConversationStateControls();

  const setConversationState = useCallback(() => {
    openThreadSidebar(
      getChatMessageIri(item.message.fields.parent_message_id),
      item.message.fields.conversation_id,
      item.message.fields.message_id,
      true,
    );
    setState({
      openThreadOfMessageId: getChatMessageIri(
        item.message.fields.parent_message_id,
      ),
      scrollToMessageId: getChatMessageIri(item.message.fields.message_id),
    });
  }, [
    item.message.fields.conversation_id,
    item.message.fields.message_id,
    item.message.fields.parent_message_id,
    openThreadSidebar,
    setState,
  ]);

  const handleInboxThreadItemClick = useCallback(() => {
    setSearchMessage({
      id: getChatMessageIri(item.message.fields.parent_message_id),
      createdAt: item.message.fields.parent_message_created_at,
      isThreadMessage: true,
    });

    inboxContext.closeInbox();
  }, [
    inboxContext,
    item.message.fields.parent_message_created_at,
    item.message.fields.parent_message_id,
    setSearchMessage,
  ]);

  return (
    <StyledInboxThreadItem
      data-testid="inbox-thread-item"
      data-entityid={item.id}
      onClickCapture={setConversationState}
      onClick={handleInboxThreadItemClick}
      to={`/workspace/${getShortId(workspace.id)}/chats/${
        item.message.fields.conversation_id
      }`}>
      <ImageContainer>
        <TopLeftCorner />
        {messageAuthor && (
          <ChatAvatar
            account={messageAuthor}
            avatarSize="2.25rem"
            showOnlineStatus={false}
          />
        )}
      </ImageContainer>
      <BodyContainer data-testid="inbox-thread-item-body">
        <ParentMessageContainer data-testid="inbox-thread-item-parent-message">
          {parentMessageAuthor && (
            <ChatAvatar
              account={parentMessageAuthor}
              avatarSize="1rem"
              showOnlineStatus={false}
              className="author-avatar"
            />
          )}
          <ParentMessage data-testid="parent-message-text">
            <RichMessage>
              {item.message.fields.parent_message_text ||
                formatMessage({
                  id: ChatTranslation.chatAttachment,
                })}
            </RichMessage>
          </ParentMessage>
        </ParentMessageContainer>
        {messageAuthor && (
          <AuthorName data-testid="thread-message-author">
            {getAccountName(messageAuthor)}
          </AuthorName>
        )}
        <MessageText data-testid="thread-message-text">
          <RichMessage>
            {item.message.fields.message_text ||
              (item.message.fields.message_has_attachments
                ? formatMessage({
                    id: ChatTranslation.chatAttachment,
                  })
                : '')}
          </RichMessage>
        </MessageText>
        <InboxCreatedAt
          title={format(parseISO(item?.createdAt), 'PPpp', {
            locale: dateFnsLocale,
          })}
          data-testid="thread-message-created-at">
          <FormattedMessage
            id={InboxTranslation.timeAgo}
            values={{
              time: formatDistanceToNow(parseISO(item?.createdAt)),
            }}
          />
        </InboxCreatedAt>
        {item.status === NotificationStatus.NEW && (
          <>
            <UnreadBadge />
            <NotificationObserver onMarkAsRead={markAsRead} />
          </>
        )}
      </BodyContainer>
    </StyledInboxThreadItem>
  );
};
