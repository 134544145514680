import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { StyledFormSelect } from '../Select/FormBrowserSelect/FormBrowserSelect.styled';
import { ReactSelect } from './ReactSelect';
import { ErrorMessage } from '../Input/FormInput';
import { TimezoneType } from '../../../domains/TimeDate/TimeDate.types';

export const FormReactSelect: FC<
  FieldProps & {
    onChange?: (value: string) => void;
    options: TimezoneType[];
    label?: string;
    className?: string;
    inlineError?: boolean;
    placeholder?: string;
  }
> = ({
  field,
  form,
  label,
  className,
  inlineError = false,
  onChange,
  options,
  meta,
  placeholder = '',
  ...props
}) => {
  const errorIdToDisplay = form.touched[field.name] && form.errors[field.name];

  return (
    <StyledFormSelect
      className={className}
      invalid={!!errorIdToDisplay}
      inlineError={inlineError}
      {...props}>
      {label && <label htmlFor={field.name}>{label}</label>}
      <ReactSelect
        field={field}
        form={form}
        onChange={
          onChange
            ? onChange
            : (value: string) => form.setFieldValue(field.name, value)
        }
        options={options}
        meta={meta}
        placeholder={placeholder}
      />
      {errorIdToDisplay && (
        <ErrorMessage
          className="error"
          inlineError={inlineError}
          data-testid="error-msg">
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormSelect>
  );
};
