import React, { memo } from 'react';
import { ChatSegment } from '../Chat/ChatSegment/ChatSegment';
import { ConferenceSegment } from '../Conference/ConferenceSegment/ConferenceSegment';
import { DesktopSegment } from '../Desktop/DesktopSegment/DesktopSegment';
import { SegmentType } from '../Segment';

interface PreviewSegmentRendererProps {
  contentType: SegmentType;
}

export const PreviewSegmentRenderer = memo<PreviewSegmentRendererProps>(
  ({ contentType }) => {
    if (contentType === SegmentType.DESKTOPS) {
      return <DesktopSegment hideVaultSegment={true} />;
    }

    if (contentType === SegmentType.CHATS) {
      return <ChatSegment />;
    }

    if (contentType === SegmentType.MEETINGS) {
      return <ConferenceSegment />;
    }

    return null;
  },
);
