import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useGetVaultAccessesChangeOwnerQuery,
} from '../../../../../../Encryption/Encryption.hooks';
import { MasterPasswordLockScreenOnMakeOwner } from '../../../../../../Encryption/MasterPasswordLockScreen';
import { Spinner } from '../../../../../../../shared/components/Spinner';
import { useCurrentWorkspace } from '../../../../../../Workspace/Workspace.hooks';
import { DangerZoneConfirmationMessage } from '../../../../../../Admin';
import { UserTranslation } from '../../../../../i18n';
import { FormattedMessage } from 'react-intl';
import { AccountApiType } from '../../../../../User.types';
import { getAccountKeyset } from '../../../../../../Encryption/Encryption.utils';
import { extractNodes } from '../../../../../../../shared/api/api.utils';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../../shared/components/Button/Button';
import { GET_WORKSPACES_BY_IDENTITY } from '../../../../../../Workspace/Workspace.queries';
import { showToastGraphQLErrors } from '../../../../../../../shared/components/Toast';
import { useChangeOwnerWorkspaceMutation } from '../../../../../User.hooks';
import {
  VaultAccessApiType,
  VaultAccessInputType,
} from '../../../../../../Encryption/Encryption.types';
import { useCurrentAccount } from '../../../../../../Auth/Auth.hooks';
import { MakeOwnerConfirmationMessageContainer } from './MakeOwner.styled';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../../../shared/components/ImportantMessage';
import {
  importPublicKey,
  reencryptVaultAccessKey,
} from '../../../../../../Encryption/Encryption.crypto.utils';
import { useAccountsContext } from '../../../../../../Account';

interface MakeOwnerProps {
  userAccount: AccountApiType;
  onDone: () => void;
}

export const MakeOwner: FC<MakeOwnerProps> = ({ userAccount, onDone }) => {
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { keyset } = useCurrentAccountKeyset();
  const { workspace } = useCurrentWorkspace();
  const { refetchAccounts } = useAccountsContext();
  const workspaceId = workspace.id;
  const { account } = useCurrentAccount();
  const [processing, setProcessing] = useState(false);

  const userKeyset = useMemo(() => {
    return getAccountKeyset(userAccount);
  }, [userAccount]);

  const { data: vaultsAccessesData, loading: loadingVaultAccessesData } =
    useGetVaultAccessesChangeOwnerQuery({
      skip: !account || !workspace || !keyset,
      variables: {
        workspace: workspaceId,
        newOwner: userAccount?.id,
      },
      fetchPolicy: 'network-only',
    });

  const vaultAccessesToReencrypt = useMemo(() => {
    return extractNodes(vaultsAccessesData?.vaultAccesses);
  }, [vaultsAccessesData]);

  const vaultIdsMap: Record<string, string> = useMemo(() => {
    return vaultAccessesToReencrypt.reduce(
      (acc, access) => ({ ...acc, [access.id]: access?.vault?.id }),
      {},
    );
  }, [vaultAccessesToReencrypt]);

  const accessKeysMap: Record<string, string> = useMemo(() => {
    return vaultAccessesToReencrypt.reduce(
      (acc, access) => ({ ...acc, [access.id]: access?.vaultKeyEncrypted }),
      {},
    );
  }, [vaultAccessesToReencrypt]);

  const reencryptVaultAccesses = useCallback(() => {
    if (!userKeyset) {
      return Promise.reject(new Error('No userKeyset'));
    }
    return importPublicKey(userKeyset.pubKey).then(userPublicKey => {
      return Promise.all(
        vaultAccessesToReencrypt.map((access: VaultAccessApiType) =>
          reencryptVaultAccessKey(
            accessKeysMap[access.id],
            privateKey,
            userPublicKey,
          ),
        ),
      ).then(reencryptedKeys => {
        return vaultAccessesToReencrypt
          .map((access: VaultAccessApiType, index) => ({
            workspace: workspaceId,
            vault: vaultIdsMap[access.id],
            keyset: userKeyset.id,
            vaultKeyEncrypted: reencryptedKeys[index] as string,
            isImplicitlyShared: true,
          }))
          .filter(newAccess => !!newAccess.vaultKeyEncrypted);
      });
    });
  }, [
    userKeyset,
    vaultAccessesToReencrypt,
    accessKeysMap,
    privateKey,
    workspaceId,
    vaultIdsMap,
  ]);

  const [changeOwnerWorkspaceMutation] = useChangeOwnerWorkspaceMutation();
  const makeOwner = useCallback(
    (vaultAccesses: VaultAccessInputType[] = []) =>
      changeOwnerWorkspaceMutation({
        variables: {
          input: {
            id: workspace!.id,
            newOwner: userAccount.id,
            vaultAccesses,
          },
        },
        refetchQueries: [
          {
            query: GET_WORKSPACES_BY_IDENTITY,
            variables: {
              id: account?.identityId,
            },
          },
        ],
      })
        .then(() => {
          refetchAccounts();
          onDone();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [
      changeOwnerWorkspaceMutation,
      workspace,
      userAccount,
      account,
      refetchAccounts,
      onDone,
    ],
  );

  const handleConfirmClick = useCallback(() => {
    setProcessing(true);
    if (vaultAccessesToReencrypt && userKeyset && privateKey) {
      return reencryptVaultAccesses()
        .then(makeOwner)
        .finally(() => setProcessing(false));
    }
    return makeOwner().finally(() => setProcessing(false));
  }, [
    vaultAccessesToReencrypt,
    userKeyset,
    privateKey,
    makeOwner,
    reencryptVaultAccesses,
  ]);

  if (loadingVaultAccessesData) {
    return <Spinner containerHeight={120} />;
  }

  if (!userKeyset && vaultAccessesToReencrypt?.length) {
    return (
      <ImportantMessage type={ImportantMessageType.WARNING}>
        <FormattedMessage
          id={UserTranslation.adminDangerZoneMakeOwnerNoKeysetErrorMessage}
        />
      </ImportantMessage>
    );
  }

  if (keyset && !privateKey && vaultAccessesToReencrypt?.length) {
    return (
      <MasterPasswordLockScreenOnMakeOwner
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  return (
    <MakeOwnerConfirmationMessageContainer>
      <DangerZoneConfirmationMessage>
        <FormattedMessage
          id={UserTranslation.adminDangerZoneMakeOwnerModalMessage}
          values={{
            workspaceName: workspace.name,
            user: userAccount.firstName,
          }}
        />
      </DangerZoneConfirmationMessage>
      <ImportantMessage type={ImportantMessageType.CRITICAL}>
        <FormattedMessage
          id={UserTranslation.adminDangerZoneMakeOwnerModalWarning}
        />
      </ImportantMessage>

      <Button
        fullWidth
        disabled={processing}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        onClick={handleConfirmClick}
        data-testid="make-owner-confirm-button">
        <FormattedMessage
          id={UserTranslation.adminDangerZoneMakeOwnerConfirmButton}
        />
      </Button>
    </MakeOwnerConfirmationMessageContainer>
  );
};
