import React, {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  StyledAppListItemBody,
  StyledAppListItemWrapper,
  StyledAppListLogin,
  StyledAppListTitle,
  StyledOpenLinkWIthCredentialsButton,
  StyledPasswordCheckboxWrapper,
  StyledPasswordImageWrapper,
} from './AppListItem.styled';
import { LaunchIcon, TeamIcon } from '../../../../../../shared/icons';
import { useIntl } from 'react-intl';
import { EncryptionTranslation } from '../../../../i18n';
import { VaultApiType } from '../../../../Encryption.types';
import { ButtonSize } from '../../../../../../shared/components/Button/Button';
import {
  getVaultApp,
  getVaultItem,
  isSharedWithKeysetId,
  isVaultShared,
} from '../../../../Encryption.utils';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceVaultLoginsMap,
} from '../../../../Encryption.hooks';
import { PasswordsImage } from '../../../PasswordsImage/PasswordsImage';
import { vaultItemDomain } from './AppListItem.utils';
import { AppListItemMenu } from './AppListItemMenu';
import { SelectVaultContext } from '../../SelectVault.context';
import { Checkbox } from '../../../../../../shared/components/Checkbox';
import { isMacOS } from '../../../../../../shared/utils/user-agent';
import { usePreviewSegment } from '../../../../../PreviewSegment/PreviewSegment.hooks';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { useMobile, useTablet } from '../../../../../../shared/hooks';

interface AppsListProps {
  vault: VaultApiType;
  onSelect: () => void;
  onOpenApp: () => void;
  isActive: boolean;
  renderItemButtons?: boolean;
}

export const AppListItem: FC<AppsListProps> = ({
  vault,
  onSelect,
  onOpenApp,
  isActive,
  renderItemButtons,
}) => {
  const intl = useIntl();
  const { loginsMap } = useCurrentWorkspaceVaultLoginsMap();
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;
  const login = loginsMap?.[vault.id];
  const { selectVault, unselectVault, selectedVaults } =
    useContext(SelectVaultContext);
  let isVaultItemSelected = selectedVaults[vault.id];
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { isPreviewMode } = usePreviewSegment();
  const isMobile = useMobile();
  const isTablet = useTablet();

  const isShared = useMemo(() => {
    return isVaultShared(vault);
  }, [vault]);

  const isSharedWithCurrentUser = useMemo(() => {
    if (keysetId) {
      return isSharedWithKeysetId(vault, keysetId);
    }
    return false;
  }, [vault, keysetId]);

  const app = useMemo(() => {
    return getVaultApp(vault);
  }, [vault]);

  const vaultItem = useMemo(() => {
    return getVaultItem(vault);
  }, [vault]);

  const vaultDomain = useMemo(
    () => vaultItemDomain(vaultItem?.url),
    [vaultItem],
  );

  const imageSrc = app?.logo.contentUrl || vaultItem?.image?.contentUrl;
  const name = app?.fullName || vaultDomain;
  const handleOpenLinkButtonClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onOpenApp();
    },
    [onOpenApp],
  );

  const handleCheckboxClick = () => {
    if (selectedVaults[vault.id]) {
      unselectVault(vault.id);
    } else {
      selectVault(vault.id);
    }
  };

  const handleSelectVaultMenuClick = () => {
    selectVault(vault.id);
  };

  const handleUnselectVaultMenuClick = () => {
    unselectVault(vault.id);
  };

  const handleClick = (event: MouseEvent) => {
    if ((isMacOS() && event.metaKey) || (!isMacOS() && event.ctrlKey)) {
      event.preventDefault();
      event.stopPropagation();
      if (selectedVaults[vault.id]) {
        unselectVault(vault.id);
      } else {
        selectVault(vault.id);
      }
    } else {
      onSelect();
    }
  };

  return (
    <StyledAppListItemWrapper
      onClick={handleClick}
      className={isActive ? 'active' : undefined}
      data-testid="app-list-item"
      selected={isVaultItemSelected}
      isMobile={isMobile}
      isTablet={isTablet}
      data-vault-item-type={vaultItem?.type}
      data-entityid={vaultItem?.id}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}>
      <StyledPasswordCheckboxWrapper
        isHidden={!selectedVaults[vault.id] && (!mouseIsOver || isPreviewMode)}>
        <Checkbox
          checked={selectedVaults[vault.id]}
          spaced={true}
          onChange={handleCheckboxClick}
          onClick={e => e.stopPropagation()}
          data-tooltip-id="global-tooltip"
          data-tooltip-place={TooltipPlace.bottom}
          data-tooltip-content={
            !selectedVaults[vault.id]
              ? intl.formatMessage({
                  id: EncryptionTranslation.passwordSelectTooltip,
                })
              : ''
          }
        />
      </StyledPasswordCheckboxWrapper>
      <StyledPasswordImageWrapper
        isHidden={selectedVaults[vault.id] || (mouseIsOver && !isPreviewMode)}>
        <PasswordsImage
          size={32}
          url={imageSrc}
          data-testid="logo"
          defaultMargin={false}
        />
      </StyledPasswordImageWrapper>
      <StyledAppListItemBody data-testid="body">
        <StyledAppListTitle
          data-testid="title"
          isPreviewMode={isPreviewMode}
          selected={isActive}>
          <div data-testid="name">{name}</div>
          {isShared && (
            <TeamIcon
              aria-label={intl.formatMessage({
                id: EncryptionTranslation.passwordsPagePasswordSharedIcon,
              })}
              data-testid="shared-icon"
            />
          )}
        </StyledAppListTitle>
        <StyledAppListLogin data-testid="login" className="login">
          {login}
        </StyledAppListLogin>
      </StyledAppListItemBody>
      {!renderItemButtons && (
        <StyledOpenLinkWIthCredentialsButton
          data-testid="open-link-button"
          icon={LaunchIcon}
          size={ButtonSize.sm}
          aria-label={intl.formatMessage({
            id: EncryptionTranslation.passwordsPagePasswordLaunchIcon,
          })}
          data-tooltip-id="global-tooltip"
          onClick={handleOpenLinkButtonClick}
          data-tooltip-place={TooltipPlace.bottom}
          data-tooltip-content={intl.formatMessage({
            id: EncryptionTranslation.passwordLaunchButtonTooltip,
          })}
        />
      )}
      <AppListItemMenu
        vaultId={vault.id}
        disableEditButton={isSharedWithCurrentUser}
        disableShareButton={isSharedWithCurrentUser}
        onSelectPassword={handleSelectVaultMenuClick}
        onUnselectPassword={handleUnselectVaultMenuClick}
        isVaultItemSelected={isVaultItemSelected}
      />
    </StyledAppListItemWrapper>
  );
};
