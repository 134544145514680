import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import {
  CustomConfigurationMapApiType,
  WorkspaceAppConfigurationApiType,
} from '../../../../../../AppStore.types';
import { StyledClaimsMapper } from './ClaimsMapper.styled';
import { BrowserSelect } from '../../../../../../../../shared/components/Select';
import { StyledFormSelect } from '../../../../../../../../shared/components/Select/FormBrowserSelect/FormBrowserSelect.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../../../shared/components/Button/Button';
import { Menu, MenuItem } from '../../../../../../../../shared/components/Menu';
import { DeleteIcon } from '../../../../../../../../shared/icons';
import {
  FormInputLabel,
  Input,
  StyledFormInput,
} from '../../../../../../../../shared/components/Input';
import {
  getClaimTranslationDescriptor,
  getClaimValueTranslationDescriptor,
} from './ClaimsMapper.utils';
import { AppStoreTranslation } from '../../../../../../i18n';

interface CustomClaimsProps {
  customConfigurationMap: CustomConfigurationMapApiType;
  workspaceConfig?: WorkspaceAppConfigurationApiType;
  readOnly?: boolean;
}

const CUSTOM_CLAIM = 'custom';
const CLAIMS_FIELD_NAME = 'claims';

const guessClaimValue = (claim: string, possibleValues: string[]) => {
  const match = possibleValues.find(value => value === claim);
  return match ?? possibleValues[0];
};

export const ClaimsMapper: FC<CustomClaimsProps> = ({
  customConfigurationMap,
  workspaceConfig,
  readOnly = false,
}) => {
  const requiredClaims = useMemo(
    () =>
      Object.keys(customConfigurationMap.claims).filter(
        key => customConfigurationMap.claims[key],
      ),
    [customConfigurationMap.claims],
  );

  const [values, setValues] = useState<
    Array<{ claim: string; name?: string; value: string }>
  >(() =>
    workspaceConfig && workspaceConfig.values[CLAIMS_FIELD_NAME]
      ? Object.keys(workspaceConfig.values[CLAIMS_FIELD_NAME]!)
          .map(key => ({
            claim: key in customConfigurationMap.claims ? key : CUSTOM_CLAIM,
            name: key in customConfigurationMap.claims ? undefined : key,
            value: workspaceConfig.values[CLAIMS_FIELD_NAME]![key],
          }))
          .sort((a, b) => {
            const aRequired = requiredClaims.includes(a.claim);
            const bRequired = requiredClaims.includes(b.claim);
            if (aRequired || bRequired) {
              if (aRequired && bRequired) return 0;
              return aRequired ? -1 : 1;
            }
            const aCustom = !!a.name;
            const bCustom = !!b.name;
            if (aCustom || bCustom) {
              if (aCustom && bCustom) return 0;
              return aCustom ? 1 : -1;
            }
            return 0;
          })
      : requiredClaims.map(claim => ({
          claim,
          value: guessClaimValue(claim, customConfigurationMap.values),
        })),
  );

  const updateClaimValue = useCallback(
    (data: { name: string } | { value: string }, index: number) => {
      setValues(prevValues =>
        prevValues.map((d, i) => (i === index ? { ...d, ...data } : d)),
      );
    },
    [],
  );

  const usedClaims = useMemo(() => values.map(({ claim }) => claim), [values]);

  const unusedClaims = useMemo(
    () =>
      Object.keys(customConfigurationMap.claims).filter(
        key => key === CUSTOM_CLAIM || !usedClaims.includes(key),
      ),
    [customConfigurationMap.claims, usedClaims],
  );

  const addClaimButtonRef = useRef<HTMLButtonElement>(null);

  const addClaimToValues = useCallback(
    (claim: string) => {
      setValues(prevValues => [
        ...prevValues,
        {
          claim,
          ...(claim === CUSTOM_CLAIM ? { name: '' } : null),
          value: guessClaimValue(claim, customConfigurationMap.values),
        },
      ]);
    },
    [customConfigurationMap.values],
  );

  const intl = useIntl();

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      CLAIMS_FIELD_NAME,
      values.reduce<Record<string, string>>(
        (acc, item) => ({
          ...acc,
          ...(item.claim === CUSTOM_CLAIM
            ? item.name
              ? { [item.name]: item.value }
              : null
            : { [item.claim]: item.value }),
        }),
        {},
      ),
    );
  }, [setFieldValue, values]);

  return (
    <StyledClaimsMapper>
      {values.map((item, index) => (
        <div className="claim-wrapper" key={index}>
          <StyledFormInput className="claim-name">
            <FormInputLabel htmlFor={`claimName${index}`}>
              {intl.formatMessage({
                id: AppStoreTranslation.customSsoClaimNameLabel,
              })}
            </FormInputLabel>
            <Input
              id={`claimName${index}`}
              value={
                item.claim === CUSTOM_CLAIM
                  ? item.name
                  : intl.formatMessage(
                      getClaimTranslationDescriptor(item.claim),
                    )
              }
              onChange={e => updateClaimValue({ name: e.target.value }, index)}
              readOnly={readOnly || item.claim !== CUSTOM_CLAIM}
            />
          </StyledFormInput>
          <div className="claim-value-container">
            <StyledFormSelect className="claim-value">
              <label htmlFor={`claimValue${index}`}>
                {intl.formatMessage({
                  id: AppStoreTranslation.customSsoClaimValueLabel,
                })}
              </label>
              <BrowserSelect
                id={`claimValue${index}`}
                value={item.value}
                onChange={e =>
                  updateClaimValue({ value: e.target.value }, index)
                }
                disabled={readOnly}>
                {customConfigurationMap.values.map(optionValue => (
                  <option key={optionValue} value={optionValue}>
                    {intl.formatMessage(
                      getClaimValueTranslationDescriptor(optionValue),
                    )}
                  </option>
                ))}
              </BrowserSelect>
            </StyledFormSelect>
            {!requiredClaims.includes(item.claim) && (
              <button
                type="button"
                className="remove-claim"
                onClick={() =>
                  setValues(prevValues =>
                    prevValues.filter((_, i) => i !== index),
                  )
                }
                disabled={readOnly}>
                <DeleteIcon />
              </button>
            )}
          </div>
        </div>
      ))}
      <Button
        className={values.length ? undefined : 'mt-1'}
        ref={addClaimButtonRef}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        type="button"
        onClick={() => {
          if (unusedClaims.length === 1) {
            addClaimToValues(unusedClaims[0]);
          }
        }}
        disabled={readOnly}>
        {intl.formatMessage({
          id: AppStoreTranslation.customSsoAddClaimButton,
        })}
      </Button>
      {unusedClaims.length > 1 && (
        <Menu trigger={addClaimButtonRef}>
          {unusedClaims.map(claim => (
            <MenuItem key={claim} onClick={() => addClaimToValues(claim)}>
              {intl.formatMessage(getClaimTranslationDescriptor(claim))}
            </MenuItem>
          ))}
        </Menu>
      )}
    </StyledClaimsMapper>
  );
};
