import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { SegmentTranslation } from '../../../Segment/i18n';
import { SegmentContent } from '../../../Segment';
import { PasswordsPage } from '../PasswordsPage/PasswordsPage';
import { PasswordsSwitcher } from '../PasswordsSwitcher/PasswordsSwitcher';
import { PasswordsNavigatorTitleActions } from './PasswordsNavigatorTitleActions/PasswordsNavigatorTitleActions';
import { MasterPasswordLockScreen } from '../../MasterPasswordLockScreen';
import {
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultsList,
} from '../../Encryption.hooks';
import {
  MasterPasswordLockScreenContaner,
  PasswordsSegmentNavigator,
} from './PasswordsSegment.styled';
import { usePreviewSegment } from '../../../PreviewSegment/PreviewSegment.hooks';
import { MembersUniversal } from '../../../Chat/MembersUniversal';
import { EncryptedState } from '../EncryptedState';
import { useSegmentTrackingEventOnInit } from '../../../Segment/tracking/SegmentTracking.hooks';

export const PasswordsSegment: FC = () => {
  const { formatMessage } = useIntl();
  const { isPreviewMode } = usePreviewSegment();
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { workspaceVaultsLoading } = useCurrentWorkspaceVaultsList();
  const showUnlockPasswordsScreen = !workspaceVaultsLoading && !privateKey;

  useSegmentTrackingEventOnInit('open_passwords');

  return (
    <>
      {showUnlockPasswordsScreen ? (
        <SegmentContent>
          <EncryptedState />
        </SegmentContent>
      ) : (
        <>
          <PasswordsSegmentNavigator
            defaultPadding={false}
            segmentTitle={formatMessage({
              id: SegmentTranslation.segmentPasswords,
            })}
            navigatorTitleActions={<PasswordsNavigatorTitleActions />}
            secondarySegment>
            {privateKey && <PasswordsSwitcher />}

            {!privateKey && isPreviewMode && (
              <MasterPasswordLockScreenContaner>
                <MasterPasswordLockScreen
                  decryptCurrentAccountPrivateKey={
                    decryptCurrentAccountPrivateKey
                  }
                />
              </MasterPasswordLockScreenContaner>
            )}
          </PasswordsSegmentNavigator>
          <SegmentContent secondarySegment>
            <PasswordsPage />
          </SegmentContent>
        </>
      )}
      <MembersUniversal addGridArea />
    </>
  );
};
