import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Footer,
  MessagesLoading,
  StyledConversatinInnerContainer,
  StyledConversationView,
} from './ConversationView.styled';
import { MessageForm } from './MessageForm';
import { useCurrentConversation } from '../../Chat.hooks';
import {
  ChatConversationInternalType,
  ChatMessageAssetApiType,
  ConversationType,
} from '../../Chat.types';
import { useChatItems } from './ConversationView.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import { ConversationDropZone } from './ConversationDropZone';
import { VirtuosoHandle } from 'react-virtuoso';
import { MessageListContainer, MessagesList } from './MessagesList';
import { useCurrentDesktopPermissions } from '../../../Desktop/Desktop.hooks';
import { useChatMessageRepository } from '../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { ConversationViewContext } from './ConversationView.context';
import { MessageListContext } from './MessagesList/MessageListProvider/MessageList.context';
import { DELAY_FOR_SCROLL_TO_LAST_MESSAGE } from './MessagesList/MessageList.constants';
import { ConversationDraftContext } from '../ConversationDraft/ConversationDraft.context';
import { ChatMessageThreadSidebar } from './ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar';
import { ChatMessageThreadSidebarContext } from './ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.context';
import { useChatConversationRepository } from '../../Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { getShortId } from '../../../../shared/utils/id';
import { useLocation } from 'react-router';
import { useIsChatsSegment } from '../../../Segment/Segment.hooks';
import { PermissionContext } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface ConversationViewProps {
  allowAttachments?: boolean;
  allowReply?: boolean;
  linkDesktopId?: string;
}

export const ConversationView: FC<ConversationViewProps> = ({
  allowAttachments = true,
  allowReply = true,
  linkDesktopId,
}) => {
  const {
    messages,
    setSearchMessage,
    searchMessage,
    listLoading,
    loadingBefore,
    loadingAfter,
  } = useContext(ConversationViewContext);

  const location = useLocation();
  const { virtualListRef } = useContext(MessageListContext);
  const { loading: draftLoading } = useContext(ConversationDraftContext);
  const { conversation } = useCurrentConversation();
  const conversationId = conversation?.id as string;
  const prevConversationRef = useRef<ChatConversationInternalType | null>(null);

  const { canCommentLinks } = useCurrentDesktopPermissions(
    PermissionContext.user,
    linkDesktopId,
  );
  const { createChatMessage } = useChatMessageRepository();
  const { threadSidebarIsOpen } = useContext(ChatMessageThreadSidebarContext);
  const { unHideConversation, hideConversationIDDB } =
    useChatConversationRepository();
  const isChatSegment = useIsChatsSegment();

  const [dragAttachments, onFilesDropped] = useState<File[] | undefined>();

  const conversationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!conversation || !isChatSegment) {
      return;
    }

    prevConversationRef.current = conversation;
  }, [conversation, isChatSegment]);

  useEffect(() => {
    if (
      !listLoading &&
      location.pathname.includes(getShortId(conversationId)) &&
      conversation?.isHidden
    ) {
      unHideConversation(conversationId);
    }
  }, [
    conversation?.isHidden,
    conversationId,
    listLoading,
    location.pathname,
    unHideConversation,
  ]);

  useEffect(() => {
    return () => {
      const prevConversation = prevConversationRef.current;
      if (conversationId && prevConversation?.isHiddenAt) {
        hideConversationIDDB(conversationId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(
    () => () => {
      if (prevConversationRef.current) {
        prevConversationRef.current = null;
      }
    },
    [isChatSegment],
  );

  useEffect(
    () => () => {
      if (conversationId && conversation) {
        prevConversationRef.current = conversation;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversationId],
  );

  /**
   * Clears the search message.
   * Run this when cached items catches up with already cached items.
   */
  const clearSearchMessage = useCallback(() => {
    setSearchMessage(undefined);
  }, [setSearchMessage]);

  const chatItems = useChatItems(messages || []);

  const clearDropZoneState = useCallback(() => {
    onFilesDropped(undefined);
  }, []);

  /**
   * Virtual scroll
   */
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const isInputDisabled = useMemo(() => {
    if (conversation?.type === ConversationType.link) {
      return canCommentLinks;
    }

    return true;
  }, [canCommentLinks, conversation]);

  const messageFormDisabled =
    conversation &&
    conversation.type !== ConversationType.appIntegration &&
    isInputDisabled;

  const scrollToLastMessage = useCallback(() => {
    setTimeout(
      () =>
        virtualListRef?.scrollIntoView({
          index: chatItems.data?.length + 1,
          align: 'end',
        }),
      DELAY_FOR_SCROLL_TO_LAST_MESSAGE,
    );
  }, [chatItems.data?.length, virtualListRef]);

  const handleCreateChatMessage = useCallback(
    async (
      value: string,
      parentChatMessageId?: string | null,
      assets?: ChatMessageAssetApiType[],
    ) => {
      if (conversation?.isHiddenAt) {
        await unHideConversation(conversation.id, false);
      }

      return await createChatMessage(
        value,
        parentChatMessageId,
        assets,
        conversation?.id,
      ).then(() => {
        scrollToLastMessage();
      });
    },
    [conversation, createChatMessage, scrollToLastMessage, unHideConversation],
  );

  return (
    <StyledConversationView
      ref={conversationRef}
      data-testid="conversation-view">
      <StyledConversatinInnerContainer
        data-testid="chat-messages-view"
        threadSidebarIsOpen={threadSidebarIsOpen}
        currentConversationWidth={conversationRef.current?.offsetWidth || 0}>
        {allowAttachments && (
          <ConversationDropZone
            width={conversationRef?.current?.offsetWidth || 0}
            height={conversationRef?.current?.offsetHeight || 0}
            onFilesDropped={onFilesDropped}
          />
        )}

        <MessageListContainer data-testid="message-list">
          <>
            {loadingBefore && (
              <MessagesLoading
                placement="top"
                data-testid="message-list-header-loading">
                <Spinner size={30} />
              </MessagesLoading>
            )}

            {loadingAfter && (
              <MessagesLoading
                placement="bottom"
                data-testid="message-list-header-loading">
                <Spinner size={30} />
              </MessagesLoading>
            )}

            <MessagesList
              ref={virtuosoRef}
              key={conversationId}
              chatItems={chatItems.data}
              searchMessage={searchMessage}
              clearSearchMessage={clearSearchMessage}
              allowReply={allowReply}
            />
          </>
        </MessageListContainer>

        <Footer data-testid="conversation-footer">
          {messageFormDisabled && (
            <MessageForm
              key={conversationId}
              formId={conversationId}
              conversation={conversation}
              onSubmit={handleCreateChatMessage}
              canSaveDraft={conversation?.type !== ConversationType.link}
              dragAttachments={dragAttachments}
              clearDropZoneState={clearDropZoneState}
              draftLoaded={!draftLoading}
              allowAttachments={allowAttachments}
              formDisabled={listLoading && chatItems.data.length === 0}
            />
          )}
        </Footer>
      </StyledConversatinInnerContainer>

      {conversationRef?.current?.offsetWidth && allowReply && (
        <ChatMessageThreadSidebar
          conversationWidth={conversationRef.current.offsetWidth}
        />
      )}
    </StyledConversationView>
  );
};
