import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import {
  CustomIntegrationField,
  CustomIntegrationFormBody,
  CustomIntegrationFormControls,
} from '../CustomIntegrationView.styled';
import { FormInput } from '../../../../../../shared/components/Input';
import { SecretInputFieldWithIcon } from '../SecretInputFieldWithIcon';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import {
  CustomIntegrationHookFormValues,
  IntegrationHookApiType,
} from '../../../../AppIntegrations.types';
import { FormBrowserSelect } from '../../../../../../shared/components/Select';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../../../shared/utils/id';
import { AppIntegrationsTranslation } from '../../../../i18n';
import { useDesktopsRepository } from '../../../../../Desktop/data/Desktop/Desktop.repositories';

interface IntegrationHookProps {
  integrationHook?: IntegrationHookApiType;
  onSubmit: (values: CustomIntegrationHookFormValues) => void;
  onCancel: () => void;
}

export const IntegrationHookForm: FC<IntegrationHookProps> = ({
  onCancel,
  integrationHook,
  onSubmit,
}) => {
  const intl = useIntl();
  const { workspace } = useCurrentWorkspace();

  const { desktops } = useDesktopsRepository({
    variables: {
      shareable: false,
    },
  });

  const initialValues: CustomIntegrationHookFormValues = {
    desktop: integrationHook?.desktop.id || '',
    hookId: integrationHook ? getShortId(integrationHook.id) : '',
    secret: integrationHook?.secret || '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, dirty, errors, touched }) => (
        <Form data-testid="custom-integration-hook-form">
          <CustomIntegrationFormBody>
            <CustomIntegrationField>
              <Field
                name="desktop"
                type="text"
                data-testid="desktop-input"
                disabled={integrationHook}
                placeholder={intl.formatMessage({
                  id: AppIntegrationsTranslation.appCustomIntegrationDesktopPlaceholder,
                })}
                label={intl.formatMessage({
                  id: AppIntegrationsTranslation.appCustomIntegrationDesktopLabel,
                })}
                component={FormBrowserSelect}>
                <option value="">
                  {intl.formatMessage({
                    id: AppIntegrationsTranslation.appCustomIntegrationDesktopPlaceholder,
                  })}
                </option>
                {desktops.map(desktop => (
                  <option value={desktop.id} key={desktop.id}>
                    {desktop.name}
                  </option>
                ))}
              </Field>
            </CustomIntegrationField>

            {integrationHook && (
              <>
                <CustomIntegrationField>
                  <Field
                    name="hookId"
                    type="text"
                    data-testid="hook-id-input"
                    disabled={integrationHook}
                    label={intl.formatMessage({
                      id: AppIntegrationsTranslation.appCustomIntegrationHookIdLabel,
                    })}
                    component={FormInput}
                  />
                </CustomIntegrationField>
                <CustomIntegrationField>
                  <Field
                    name="secret"
                    displayErrors={false}
                    component={SecretInputFieldWithIcon}
                  />
                </CustomIntegrationField>
              </>
            )}
          </CustomIntegrationFormBody>

          {!integrationHook && (
            <CustomIntegrationFormControls>
              <Button
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                className="cancel-button"
                onClick={onCancel}
                data-testid="cancel-button">
                <FormattedMessage
                  id={
                    AppIntegrationsTranslation.appCustomIntegrationCancelButton
                  }
                />
              </Button>
              <Button
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={!dirty || isSubmitting}
                data-testid="submit-button">
                <FormattedMessage
                  id={AppIntegrationsTranslation.appCustomIntegrationSaveButton}
                />
              </Button>
            </CustomIntegrationFormControls>
          )}
        </Form>
      )}
    </Formik>
  );
};
