import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import _uniq from 'lodash/uniq';
import {
  IconWrapper,
  JoinButton,
  ParticipantsCounter,
  StyledConferenceMessage,
  StyledConferenceMessageSpacer,
  StyledNoParticipantsInConference,
} from './ConferenceMessage.styled';
import type { ChatConferenceMessageInternalType } from '../../../../Chat.types';
import { ParticipantAvatars } from '../../../ConversationHeader/ParticipantAvatars';
import { ButtonSize } from '../../../../../../shared/components/Button/Button';
import { ChatTranslation } from '../../../../i18n';
import { CallEndedIcon, CallIcon } from '../../../../../../shared/icons';
import { useAccountsContext } from '../../../../../Account';
import { useMobile, useQueryParams } from '../../../../../../shared/hooks';
import { useInboundCallControls } from '../../../../../Conference/Conference.hooks';

interface ConferenceMessageProps {
  className?: string;
  chatMessage: ChatConferenceMessageInternalType;
}

export const ConferenceMessage: FC<ConferenceMessageProps> = ({
  className,
  chatMessage,
}) => {
  const queryParams = useQueryParams();
  const isMobile = useMobile();

  const { active } = chatMessage.context;
  const { embeddedView } = queryParams;

  const { accountsWithAvailability } = useAccountsContext();

  const participants = useMemo(
    () =>
      _uniq(chatMessage.context.participants)
        .map(accountId => accountsWithAvailability[accountId])
        .filter(Boolean),
    [accountsWithAvailability, chatMessage.context.participants],
  );

  const participantsTotal = useMemo(
    () =>
      _uniq(chatMessage.context.participants_total)
        ?.map(accountId => accountsWithAvailability[accountId])
        .filter(Boolean),
    [accountsWithAvailability, chatMessage.context.participants_total],
  );

  const participantsToShow = active
    ? participants
    : participantsTotal || participants;

  const { acceptAndOpenInboundCall } = useInboundCallControls();

  return (
    <>
      <StyledConferenceMessageSpacer />
      <StyledConferenceMessage
        isMobile={isMobile}
        className={className}
        data-testid="conference-message">
        <header data-testid="header">
          <IconWrapper active={active}>
            {active ? <CallIcon /> : <CallEndedIcon />}
          </IconWrapper>
          <FormattedMessage
            id={
              active
                ? ChatTranslation.chatConferenceMessageCallStarted
                : ChatTranslation.chatConferenceMessageCallEnded
            }
          />
        </header>
        <div className="divider" />
        <footer data-testid="conference-footer">
          <ParticipantAvatars
            participants={participantsToShow}
            avatarSize="2rem"
            overlaySize="-0.5rem"
          />
          {active && !embeddedView ? (
            <>
              {!participantsToShow.length && (
                <StyledNoParticipantsInConference data-testid="no-participants-in-active-conference">
                  <FormattedMessage
                    id={ChatTranslation.chatConferenceMessageNoOneInTheCall}
                  />
                </StyledNoParticipantsInConference>
              )}

              <JoinButton
                size={ButtonSize.sm}
                onClick={() =>
                  acceptAndOpenInboundCall(
                    chatMessage.context.chatConferenceIri,
                  )
                }
                data-testid="join-button">
                <FormattedMessage
                  id={ChatTranslation.chatConferenceMessageJoin}
                />
              </JoinButton>
            </>
          ) : (
            <ParticipantsCounter data-testid="participant-counter-in-inactive-conference">
              <FormattedMessage
                id={ChatTranslation.chatConferenceMessageParticipantsCounter}
                values={{
                  participantsCount: participantsToShow.length,
                }}
              />
            </ParticipantsCounter>
          )}
        </footer>
      </StyledConferenceMessage>
    </>
  );
};
