import React, { FC, useEffect } from 'react';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  NotificationsSectionBody,
  NotificationsSectionBodyItem,
  NotificationsSectionTitleWrapper,
} from './NotificationsSettingsForm.styled';
import { Section, SectionHeader, SectionHeaderTitle } from '../../../Admin';
import { FormToggle } from '../../../../shared/components/Toggle/FormToggle';
import { Spinner } from '../../../../shared/components/Spinner';
import { GeneralSettingsTranslation } from '../../i18n';
import { CustomNotificationsFormFields } from '../CustomNotificationsSettingsForm';
import { FormBrowserSelect } from '../../../../shared/components/Select';
import {
  AdminNotificationsSettingsApiType,
  AdminNotificationsSettingsFormValues,
} from '..';
import { SendNotificationsType } from '.';
import { MOBILE_NOTIFICATIONS_FIELD_DROPDOWN_TYPE } from './NotificationsSettingsForm.constants';
import { useMobile } from '../../../../shared/hooks';

export interface NotificationsSettingsFormProps {
  settings: AdminNotificationsSettingsApiType;
  onSubmit: (
    formValues: AdminNotificationsSettingsFormValues,
    formikHelpers: FormikHelpers<AdminNotificationsSettingsFormValues>,
  ) => void;
}

export const FormikAutoSave: FC = () => {
  const { submitForm, values, dirty } = useFormikContext();
  useEffect(() => {
    if (values && dirty) {
      submitForm();
    }
  }, [dirty, submitForm, values]);

  return null;
};

export const NotificationsSettingsForm: FC<NotificationsSettingsFormProps> = ({
  onSubmit,
  settings,
}) => {
  const intl = useIntl();
  const isMobile = useMobile();

  if (!settings) {
    return <Spinner />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: settings.id,
        mobileNotifications: settings.mobileNotifications,
        soundsMuted: !settings.soundsEnable,
        messagesNotifications: settings.messagesNotifications,
        generalBellNotificationsEnable: settings.generalBellNotificationsEnable,
        billingNotificationsEnable: settings.billingNotificationsEnable,
        upcomingMeetingNotificationsEnable:
          settings.upcomingMeetingNotificationsEnable,
      }}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <Section>
            <SectionHeader>
              <SectionHeaderTitle data-testid="title">
                <FormattedMessage
                  id={
                    GeneralSettingsTranslation.notificationsNotActiveOnComputer
                  }
                />
              </SectionHeaderTitle>
            </SectionHeader>

            <NotificationsSectionBody data-testid="notifications-section-body">
              <NotificationsSectionBodyItem isMobile={isMobile}>
                <NotificationsSectionTitleWrapper isMobile={isMobile}>
                  <FormattedMessage
                    id={
                      GeneralSettingsTranslation.notificationsToMyMobileDevices
                    }
                  />
                </NotificationsSectionTitleWrapper>

                <Field
                  disabled={isSubmitting}
                  className="fieldBox"
                  data-testid="mobile-notifications-types-dropdown"
                  name={MOBILE_NOTIFICATIONS_FIELD_DROPDOWN_TYPE}
                  component={FormBrowserSelect}>
                  <option value={SendNotificationsType.always}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.notificationsOptionAlways,
                    })}
                  </option>
                  <option value={SendNotificationsType.inactive}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.notificationsOptionInactive,
                    })}
                  </option>
                  <option value={SendNotificationsType.never}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.notificationsOptionNever,
                    })}
                  </option>
                </Field>
              </NotificationsSectionBodyItem>
            </NotificationsSectionBody>
          </Section>

          <Section data-testid="custom-notifications-section">
            <SectionHeader>
              <SectionHeaderTitle data-testid="custom-notifications-title">
                <FormattedMessage
                  id={GeneralSettingsTranslation.notificationsNotifyMeAbout}
                />
              </SectionHeaderTitle>
            </SectionHeader>

            <NotificationsSectionBody data-testid="notifications-section-body">
              <CustomNotificationsFormFields
                values={values}
                isSubmitting={isSubmitting}
              />
            </NotificationsSectionBody>
          </Section>

          <Section>
            <SectionHeader>
              <SectionHeaderTitle data-testid="notifications-sounds-title">
                <FormattedMessage
                  id={GeneralSettingsTranslation.notificationsSounds}
                />
              </SectionHeaderTitle>
            </SectionHeader>

            <NotificationsSectionBody data-testid="notifications-mute-sounds-body">
              <NotificationsSectionBodyItem>
                <NotificationsSectionTitleWrapper>
                  <FormattedHTMLMessage
                    id={GeneralSettingsTranslation.notificationsMuteAllSounds}
                  />
                </NotificationsSectionTitleWrapper>
                <Field
                  data-testid="notifications-mute-all-sounds"
                  name="soundsMuted"
                  component={FormToggle}
                  checked={!settings.soundsEnable}
                  disabled={isSubmitting}
                />
              </NotificationsSectionBodyItem>
            </NotificationsSectionBody>

            <FormikAutoSave />
          </Section>
        </Form>
      )}
    </Formik>
  );
};
