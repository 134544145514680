/**
 * This file contains any network related functionality for chat messages
 */

import { useMemo } from 'react';
import { useCurrentConversation } from '../../../Chat.hooks';
import type { GetChatMessagesVariables } from '../../../Chat.queries';
import { convertGraphQLApiChatMessageToInternal } from '../../../Chat.utils';
import {
  showToastError,
  showToastGraphQLErrors,
} from '../../../../../shared/components/Toast';
import orderBy from 'lodash/orderBy';
import {
  bulkUpdateIDDBChatMessage,
  clearIDDBChatMessagesList,
  createIDDBChatMessage,
  createMultipleIDDBChatMessage,
  deleteIDDBChatMessage,
  getBulkIDDBChatMessagesByParentId,
  getIDDBChatMessage,
  replaceOptimisticIDDBChatMessage,
  updateIDDBChatMessage,
} from './ChatMessageIDDBRepository';
import {
  addReactionToChatMessageApi,
  createChatMessageApi,
  createDraftChatMessageApi,
  editChatMessageApi,
  getAllChatMessageThreadsApi,
  getChatLinkApi,
  getChatMessageByIdApi,
  getChatMessagesApi,
  getChatMessagesGraphQLApi,
  getChatMessagesSubsetAround,
  getDraftChatMessagesApi,
  markAllChatMessagesAsSeenApi,
  markMultipleChatMessagesAsSeenApi,
  removeChatMessageApi,
  removeReactionToChatMessageApi,
} from '../../DataSource/ChatMessage/ChatMessagesApiSource';
import {
  convertToMessageAndThreadMessage,
  messageIdIsOptimistic,
  withFirstUnreadMessage,
} from '../Repository.utils';
import type {
  ChatMessageApiType,
  ChatMessageAssetApiType,
  ChatMessageInternalType,
} from '../../../Chat.types';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../Workspace/Workspace.hooks';
import { useAuthService } from '../../../../Auth/Auth.hooks';
import parseISO from 'date-fns/parseISO';
import { GetChatMessagesGraphQLApiResponse } from '../../DataSource/ChatMessage/ChatMessages.types';
import type {
  ConversationInstance,
  UpdateConversationInstanceProps,
  UserContextProps,
} from './ChatMessage.types';
import { captureException } from '@sentry/react';
import {
  optimisticDecreaseThreadMessageCount,
  optimistiUpdateThreadInParentChatMessage,
} from '../OptimisiticResponse/OptimisticThreadResponse';
import {
  optimisticChatMessage,
  optimisticEditChatMessage,
} from '../OptimisiticResponse/OptimisticChatMessageResponse';
import { MESSAGE_LIST_PAGE_SIZE } from '../../../ChatView/ConversationView/MessagesList/MessageList.constants';
import type { SearchMessageType } from '../../../Chat.context';
import { getShortId } from '../../../../../shared/utils/id';
import { ChatMessageToChatMessageTableRow } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { getChatLinkIri } from '../../../../Mercure/Chat/handlers/chatMessage.utils';
import { useChatConversationRepository } from '../ChatConversation/ChatConversationsApiRepository';
import {
  bulkDeleteChatMessagesFromIDDB,
  getMessageByConversationId,
} from '../../DataSource/ChatMessage/ChatMessagesIndexedDBSource';
import { useIntl } from 'react-intl';
import { ChatTranslation } from '../../../i18n';

// !REMINDER FOR EVERYONE
// hasNextPage - informs us that BE has messages from TOP
// hasPreviousPage - infroms that BE has message from BOTTOM

// !Instance of Chat Conversation
// conversationInstances variable contains id of opened before conversation and some data for to be used in render logic.
// must be as global variable beacuse of context - Chat/Mercure event
const conversationInstances: Map<string, ConversationInstance> = new Map();

export const useChatMessageRepository = () => {
  const { conversation } = useCurrentConversation();
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const authService = useAuthService();
  const { unHideConversation } = useChatConversationRepository();
  const intl = useIntl();

  const userContext: UserContextProps = useMemo(
    () => ({
      conversationId: conversation?.id || '',
      accountId: account!.id,
    }),
    [account, conversation?.id],
  );

  /**
   * Get message list with variables
   */
  const getChatMessagesWithVariables = async (
    variables: Omit<GetChatMessagesVariables, 'conversation' | 'workspaceId'>,
    conversationId: string | undefined = conversation?.id,
  ) => {
    const token = await authService.getToken();

    if (!conversationId || !token) {
      return;
    }
    return await getChatMessagesApi({
      conversation: conversationId,
      workspaceId: getShortId(workspace.id),
      ...variables,
    });
  };

  /**
   * Get initial chat messages
   */
  const getInitialChatMessages = async (conversationId: string | undefined) => {
    if (!conversationId) {
      return;
    }

    const conversationInstance = conversationInstances.get(conversationId);

    if (conversationInstance) {
      return Promise.resolve();
    }

    try {
      const messagesResp = await getChatMessagesWithVariables(
        {
          first: MESSAGE_LIST_PAGE_SIZE,
        },
        conversationId,
      );

      if (!messagesResp?.data?.data || !messagesResp?.ok) {
        return;
      }

      const firstUnreadMessage = withFirstUnreadMessage(messagesResp.data, {
        first: MESSAGE_LIST_PAGE_SIZE,
      });

      if (firstUnreadMessage) {
        return await getSubsetWithFirstUnread(
          firstUnreadMessage,
          conversationId,
        );
      }

      updateConversationInstance({
        ...messagesResp.data.pageInfo,
      });

      updateMessageList(
        messagesResp.data.data,
        conversationId,
        messagesResp.data.firstUnreadMessage,
      );

      // Clear old optimistic messages (temporary fix for dupicated messages)
      const cachedMessages = await getMessageByConversationId(conversationId);
      const optimisticMessages = cachedMessages.filter(message =>
        messageIdIsOptimistic(message.id),
      );
      await bulkDeleteChatMessagesFromIDDB(
        optimisticMessages.map(message => message.id) || [],
      );
    } catch (err) {
      captureException(err);
    }
  };

  /**
   * Get list of chat messages before specific @Date
   */
  const getChatMessagesBefore = async (
    microtimetAtLessThan: string,
    limit = MESSAGE_LIST_PAGE_SIZE,
  ) => {
    if (!conversation?.id) {
      return;
    }

    return await getChatMessagesGraphQLApi({
      conversation: conversation.id,
      microtimetAtLessThan,
      first: limit,
    })
      .then(async (resp: GetChatMessagesGraphQLApiResponse) => {
        conversationInstances.set(conversation.id, {
          canLoadAfter: resp.pageInfo.hasPreviousPage,
          canLoadBefore: resp.pageInfo.hasNextPage,
        });

        return await updateMessageList(
          resp.messages ?? [],
          conversation.id,
          null,
        );
      })
      .catch(e => {
        showToastGraphQLErrors(e);
      });
  };

  /**
   * Get list of chat messages before and after specific @Date
   */
  const getChatMessagesAfter = async (
    microtimeAtGreaterThanEquals: string,
    limit: string = MESSAGE_LIST_PAGE_SIZE.toString(),
  ) => {
    if (!conversation?.id) {
      return;
    }

    const currentConversation = currentConversationInstance();

    return await getChatMessagesGraphQLApi({
      conversation: conversation.id,
      microtimeAtGreaterThanEquals,
      after: limit,
      order: {
        createdAt: 'asc',
      },
    })
      .then((resp: GetChatMessagesGraphQLApiResponse) => {
        if (!resp) {
          return [];
        }

        conversationInstances.set(conversation.id, {
          canLoadBefore: currentConversation?.canLoadBefore ?? false,
          canLoadAfter: resp.pageInfo.hasPreviousPage,
        });

        updateMessageList(resp.messages ?? [], conversation.id, null);
      })
      .catch(e => {
        showToastGraphQLErrors(e);
      });
  };

  /**
   * Get thread message by @ParentId
   */
  const getChatMessageThread = async (parentChatMessage: string) => {
    const response = await getChatMessagesWithVariables({
      parentChatMessage,
    });
    if (!response?.data || !response?.ok || !conversation?.id) {
      return;
    }

    updateMessageList(
      response.data.data ?? [],
      conversation.id,
      response.data.firstUnreadMessage,
    );
  };

  /**
   * Get list of chat messages before and after @Date
   */
  const getChatMessagesBeforeAndAfter = async (
    date: Date,
    firstUnreadMessage: ChatMessageApiType | null = null,
    conversationId: string,
  ) => {
    try {
      const cachedMessages =
        (await getMessageByConversationId(conversationId)) || [];

      if (cachedMessages?.length > 0) {
        await bulkDeleteChatMessagesFromIDDB(
          cachedMessages.map(cachedMessage => cachedMessage.id),
        );
      }

      const chatMessagesSubsetAround = await getChatMessagesSubsetAround(
        date,
        conversationId,
      );

      updateConversationInstance({
        hasNextPage: true,
        hasPreviousPage: true,
      });

      updateMessageList(
        chatMessagesSubsetAround,
        conversationId,
        firstUnreadMessage,
      );
    } catch (e) {
      captureException(e);
    }
  };

  /**
   * Remove chat message
   */
  const removeChatMessage = async (chatMessageId: string) => {
    // Fallback is in case the delete mutation fails and we need to restore the message
    const chatMessageFallback = await getIDDBChatMessage(chatMessageId);

    if (!chatMessageFallback) {
      return false;
    }

    if (chatMessageFallback.parentId) {
      try {
        const parentMessage = await getIDDBChatMessage(
          chatMessageFallback.parentId,
        );

        if (parentMessage) {
          await optimisticDecreaseThreadMessageCount(parentMessage.id);
        }
      } catch (e) {
        captureException(e);
      }
    }

    try {
      await deleteIDDBChatMessage(chatMessageFallback.id);
    } catch (e) {
      captureException(e);
    }

    removeChatMessageApi(chatMessageFallback.id)
      // TODO: Add normal optimistic response with reload button
      // .then(response => {
      //   if (!response.data && chatMessageFallback) {
      //     createIDDBChatMessage(chatMessageFallback.data);
      //   }
      // })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
        // TODO: Add normal optimistic response with reload button
        // if (chatMessageFallback) {
        //   createIDDBChatMessage(chatMessageFallback.data);
        // }
      });
  };

  /**
   * Edit chat message
   */
  const editChatMessage = async (
    chatMessageId: string,
    message: string,
    assets: ChatMessageAssetApiType[],
  ) => {
    const originalChatMessage = await getIDDBChatMessage(chatMessageId);

    if (!originalChatMessage) {
      return;
    }

    const optimisticMessage: ChatMessageInternalType =
      optimisticEditChatMessage(originalChatMessage, message, assets);

    try {
      updateIDDBChatMessage(optimisticMessage.id, optimisticMessage);
    } catch (error) {}

    const assetIds = assets.map(asset => asset.assetIri);
    editChatMessageApi(chatMessageId, message, assetIds)
      .then(async response => {
        if (!response.data) {
          if (originalChatMessage) {
            updateIDDBChatMessage(
              optimisticMessage.id,
              originalChatMessage.data,
            );
          }
          return false;
        }

        const responseMessage = response.data.editChatMessage.chatMessage;

        await updateIDDBChatMessage(
          originalChatMessage.id,
          convertGraphQLApiChatMessageToInternal(responseMessage, userContext),
        );

        return responseMessage;
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
        if (originalChatMessage) {
          createIDDBChatMessage(originalChatMessage.data);
        }
      });
  };

  /**
   * Create chat message
   */
  const createChatMessage = async (
    message: string,
    parentChatMessageId: string | null = null,
    assets: ChatMessageAssetApiType[] = [],
    conversationId: string | undefined,
  ) => {
    if (!conversationId || !account) {
      return false;
    }

    if (!parentChatMessageId) {
      createDraftChatMessage(conversationId, '');
    }

    await unHideConversation(conversationId, false);

    const optimisticMessage = optimisticChatMessage(
      message,
      parentChatMessageId,
      assets,
      account.id,
      userContext,
    );

    try {
      await createIDDBChatMessage(optimisticMessage);

      conversationInstances.set(conversationId, {
        canLoadBefore: true,
        canLoadAfter: false,
      });
    } catch (e) {
      captureException(e);
    }

    if (parentChatMessageId) {
      await optimistiUpdateThreadInParentChatMessage(
        account.id,
        parentChatMessageId,
      );
    }

    const assetsIds = assets.map(asset => asset!.assetIri);
    const conversationInstance = conversationInstances.get(conversationId);

    createChatMessageApi(
      message,
      optimisticMessage.id,
      parentChatMessageId,
      assetsIds,
      conversationId,
      optimisticMessage.createdAt.toISOString(),
    )
      .then(async response => {
        if (!response?.data) {
          return false;
        }

        if (conversationInstance?.canLoadAfter) {
          markAllChatMessagesAsSeen(conversationId);
          replaceListByLastSubset();
        }

        const responseMessage = response.data?.createChatMessage.chatMessage;

        const internalMessageType = convertGraphQLApiChatMessageToInternal(
          responseMessage,
          userContext,
        );

        replaceOptimisticIDDBChatMessage(
          optimisticMessage.id,
          internalMessageType,
        );
      })
      .catch(e => {
        if (!e.graphQLErrors.length) {
          showToastError(
            intl.formatMessage({
              id: ChatTranslation.newMessageFormMessageNotSentErrorMessage,
            }),
          );
        } else {
          showToastGraphQLErrors(e.graphQLErrors);
        }
        deleteIDDBChatMessage(optimisticMessage.id);
      });
  };

  /**
   * Mark one messages as seen
   */
  const markChatMessagesAsSeen = async (
    chatMessagesId: string[],
    conversationId?: string,
  ) => {
    if (!account || !conversationId) {
      return;
    }

    try {
      await markMultipleChatMessagesAsSeenApi(chatMessagesId, conversationId);
    } catch (e) {
      captureException(e);
    }
  };

  const markIDDBChatMessagesAsSeen = async (
    chatMessages: ChatMessageInternalType[],
  ) => {
    return await bulkUpdateIDDBChatMessage(
      chatMessages.map(message => message.id),
      (value, ref) => {
        const chatMessage = value.data;
        ref.value = ChatMessageToChatMessageTableRow({
          ...chatMessage,
          isFirstUnread: false,
          isSeen: true,
          seenBy: [...chatMessage.seenBy, account.id],
        });
      },
    );
  };

  /**
   * Mark all messages as seen
   */
  const markAllChatMessagesAsSeen = async (conversationId: string) => {
    markAllChatMessagesAsSeenApi(conversationId).catch(e => {
      showToastGraphQLErrors(e.graphQLErrors);
    });
  };

  /**
   * Get all thread messages
   */
  const getAllChatMessageThreads = async (
    chatMessageId: string,
    conversationId: string | undefined = conversation?.id,
  ) => {
    const token = await authService.getToken();

    if (!conversationId || !token) {
      return;
    }

    return getAllChatMessageThreadsApi({
      workspaceId: getShortId(workspace.id),
      conversationId: getShortId(conversationId),
      chatMessageId: getShortId(chatMessageId),
      token,
    })
      .then(async response => {
        const messagesWithLinkData = await presetMessageDataWithLinkData(
          response,
        );
        const messages = orderBy(
          messagesWithLinkData.map(item =>
            convertGraphQLApiChatMessageToInternal(item, userContext),
          ),
          ['createdAt'],
          ['asc'],
        );

        await createMultipleIDDBChatMessage(messages).catch(e => {
          captureException(e);
        });

        return messages;
      })
      .catch(e => {
        showToastGraphQLErrors(e);
        return [];
      });
  };

  /**
   * Refresh chat message list after sleep
   */
  const refreshMessageListAfterSleep = async (
    conversationId: string | undefined = conversation?.id,
  ) => {
    const token = await authService.getToken();

    if (!conversationId || !token) {
      return;
    }

    const messagesResp = await getChatMessagesApi({
      first: MESSAGE_LIST_PAGE_SIZE,
      conversation: conversationId,
      workspaceId: workspace.id,
    });

    if (!messagesResp.data || !messagesResp.ok) {
      return;
    }

    updateMessageList(
      messagesResp.data.data,
      conversationId,
      messagesResp.data.firstUnreadMessage,
    );
  };

  /**
   * Update chat message list => add/update new/old messages
   */
  const updateMessageList = async (
    respData: ChatMessageApiType[],
    conversationId: string | undefined,
    firstUnreadMessage: ChatMessageApiType | null,
  ) => {
    if (!conversationId) {
      return false;
    }

    const messagesWithLinkData = await presetMessageDataWithLinkData(respData);

    const { threadMessages, messages } = convertToMessageAndThreadMessage(
      messagesWithLinkData,
      { ...userContext, conversationId },
      firstUnreadMessage,
    );

    await createMultipleIDDBChatMessage([...messages, ...threadMessages]);
  };

  const createDraftChatMessage = async (
    conversationId: string,
    message: string = '',
    assets: string[] = [],
  ) => await createDraftChatMessageApi(conversationId, message, assets);

  const addChatMessageReaction = async (
    chatMessageId: string,
    accountId: string,
    emoji: string,
  ) => {
    const originalChatMessage = await getIDDBChatMessage(chatMessageId);

    if (!originalChatMessage) {
      return;
    }

    const isDuplicateEmoji = originalChatMessage.data.reactions.find(
      reaction =>
        reaction.userIds?.find(userId => userId === accountId) &&
        reaction.emoji === emoji,
    );

    if (isDuplicateEmoji) {
      return;
    }

    const emojiExist = originalChatMessage.data.reactions.find(
      reaction =>
        reaction.userIds?.find(userId => userId !== accountId) &&
        reaction.emoji === emoji,
    );

    if (emojiExist) {
      emojiExist.userIds.push(accountId);
    }

    const updatedMessageReactions = emojiExist
      ? [...originalChatMessage.data.reactions]
      : [
          ...originalChatMessage.data.reactions,
          {
            userIds: [accountId],
            emoji,
          },
        ];

    const updatedMessage = {
      ...originalChatMessage.data,
      reactions: updatedMessageReactions,
    };

    await createIDDBChatMessage(updatedMessage);

    await addReactionToChatMessageApi(chatMessageId, emoji).catch(e => {
      showToastGraphQLErrors(e.graphQLErrors);
    });
  };

  const removeChatMessageReaction = async (
    chatMessageId: string,
    accountId: string,
    emoji: string,
  ) => {
    const originalChatMessage = await getIDDBChatMessage(chatMessageId);

    if (!originalChatMessage?.data.reactions) {
      return;
    }

    const emojiExist = originalChatMessage.data.reactions.find(
      reaction => reaction.emoji === emoji,
    );

    if (emojiExist) {
      emojiExist.userIds = emojiExist.userIds.filter(
        userId => userId !== accountId,
      );
    }

    const newEmojiArray = originalChatMessage.data.reactions.filter(
      reaction => reaction.emoji !== emoji,
    );

    const updatedMessageReactions = emojiExist?.userIds?.length
      ? originalChatMessage.data.reactions.map(reaction => {
          if (reaction.emoji === emoji) {
            return {
              userIds: reaction.userIds.filter(userId => userId !== accountId),
              emoji,
            };
          }

          return reaction;
        })
      : newEmojiArray;

    const updatedMessage = {
      ...originalChatMessage.data,
      reactions: updatedMessageReactions,
    };

    await createIDDBChatMessage(updatedMessage);

    await removeReactionToChatMessageApi(chatMessageId, emoji);
  };

  const getSubsetWithFirstUnread = async (
    message: ChatMessageApiType,
    conversationId: string,
  ) => {
    const firstUnreadMessageDate = message.microtimeAt
      ? new Date(parseInt(message.microtimeAt, 10) / 1000)
      : parseISO(message.createdAt);

    if (!conversation?.id) {
      return;
    }

    await getChatMessagesBeforeAndAfter(
      firstUnreadMessageDate,
      message,
      conversationId,
    );
  };

  const replaceListByLastSubset = async () => {
    if (!conversation?.id) {
      return;
    }

    try {
      const messageResp = await getChatMessagesWithVariables({
        first: MESSAGE_LIST_PAGE_SIZE,
      });
      if (!messageResp?.data || !messageResp.ok) {
        return;
      }

      conversationInstances.set(conversation.id, {
        canLoadAfter: messageResp.data.pageInfo.hasPreviousPage,
        canLoadBefore: messageResp.data.pageInfo.hasNextPage,
      });

      if (messageResp?.data.data) {
        clearChatMessageListByConversationId(conversation.id);

        updateMessageList(
          messageResp.data.data,
          conversation.id,
          messageResp.data.firstUnreadMessage,
        );
      }
    } catch (err) {
      captureException(err);
    }
  };

  const currentConversationInstance = (
    conversationId: string | null = conversation?.id ?? null,
  ): ConversationInstance | null => {
    if (!conversationId) {
      return null;
    }

    return conversationInstances.get(conversationId) || null;
  };

  const updateConversationInstance = (
    props: UpdateConversationInstanceProps,
  ) => {
    if (!conversation?.id) {
      return;
    }

    const { hasNextPage, hasPreviousPage } = props;

    conversationInstances.set(conversation.id, {
      canLoadBefore: hasNextPage,
      canLoadAfter: hasPreviousPage,
    });
  };

  const clearChatMessageListByConversationId = async (conversationId: string) =>
    await clearIDDBChatMessagesList(conversationId);

  const getDraftChatMessages = async () =>
    await getDraftChatMessagesApi(workspace.id).catch(e => {
      showToastGraphQLErrors(e);
    });

  const findMessageInList = async (
    searchMessage: SearchMessageType,
    conversationId: string,
  ) => {
    const message = await getIDDBChatMessage(searchMessage.id);

    if (message) {
      return;
    }

    return await getChatMessagesBeforeAndAfter(
      new Date(searchMessage.createdAt),
      null,
      conversationId,
    );
  };

  const getMessageWithAllThreads = async (
    conversationId: string,
    chatMessageId: string,
  ) => {
    try {
      const cachedMessage = await getIDDBChatMessage(chatMessageId);

      if (cachedMessage?.data) {
        const cachedThreads = await getBulkIDDBChatMessagesByParentId(
          cachedMessage.id,
        );

        if (cachedThreads?.length === cachedMessage.data.threadMessagesCount) {
          return cachedThreads;
        }

        const threads = await getAllChatMessageThreads(
          cachedMessage.data.id,
          conversationId,
        );

        if (threads) {
          createMultipleIDDBChatMessage(threads);
        }
      }

      return null;
    } catch (err) {
      captureException(err);
      return null;
    }
  };

  const getChatMessageById = async (
    workspaceId: string,
    conversationId: string,
    chatMessageId: string,
  ) => {
    const cachedMessage = await getIDDBChatMessage(chatMessageId);

    if (cachedMessage) {
      return cachedMessage;
    }

    const token = await authService.getToken();

    if (!token) {
      return null;
    }

    return await getChatMessageByIdApi({
      workspaceId: getShortId(workspaceId),
      conversationId: getShortId(conversationId),
      chatMessageId: getShortId(chatMessageId),
      token,
    }).then(async apiMessage => {
      const message = convertGraphQLApiChatMessageToInternal(apiMessage, {
        ...userContext,
        conversationId,
      });

      await createIDDBChatMessage(message);

      return ChatMessageToChatMessageTableRow(message);
    });
  };

  const removeChatMessageInIDDB = async (chatMessageId: string) => {
    const cachedMessage = await getIDDBChatMessage(chatMessageId);

    if (!cachedMessage) {
      return;
    }

    deleteIDDBChatMessage(cachedMessage.id);
  };

  const getChatLink = async (linkId: string) => {
    try {
      const { data } = await getChatLinkApi(linkId);

      if (data) {
        return data;
      }

      return;
    } catch (err) {
      captureException(err);
      return;
    }
  };

  const presetMessageDataWithLinkData = (
    chatMessage: ChatMessageApiType[],
  ): Promise<ChatMessageApiType[]> => {
    const messagesWithLinkData = chatMessage.map(async message => {
      const singleChatLinkIri = getChatLinkIri(message.context);

      if (singleChatLinkIri) {
        const linkData = await getChatLink(singleChatLinkIri);

        if (!linkData) {
          return message;
        }

        return {
          ...message,
          linkData: linkData.chatLink,
        };
      }
      return message;
    });

    return Promise.all(messagesWithLinkData);
  };

  const setScrollPosition = (listPosition: number) => {
    if (!conversation?.id) {
      return;
    }

    const currentConversation = conversationInstances.get(conversation.id);

    if (!currentConversation) {
      return;
    }

    conversationInstances.set(conversation.id, {
      ...currentConversation,
      listPosition,
    });
  };

  return {
    getInitialChatMessages,
    getChatMessagesBefore,
    getChatMessagesAfter,
    getChatMessagesBeforeAndAfter,
    getChatMessageThread,
    removeChatMessage,
    editChatMessage,
    createChatMessage,
    markChatMessagesAsSeen,
    markAllChatMessagesAsSeen,
    getAllChatMessageThreads,
    refreshMessageListAfterSleep,
    updateMessageList,
    createDraftChatMessage,
    addChatMessageReaction,
    removeChatMessageReaction,
    replaceListByLastSubset,
    currentConversationInstance,
    clearChatMessageListByConversationId,
    getDraftChatMessages,
    markIDDBChatMessagesAsSeen,
    findMessageInList,
    getMessageWithAllThreads,
    getChatMessageById,
    removeChatMessageInIDDB,
    getChatLink,
    setScrollPosition,
  };
};
