import { AccountApiType } from '../User/User.types';
import { AccountInvitationApiType } from '../Invitation/data/Invitation/types/Invitation.types';

export const accountMatchesInvitation = (
  workspaceAccounts: AccountApiType[],
  invitation: AccountInvitationApiType | null | undefined,
): boolean => {
  if (!invitation || !workspaceAccounts) {
    return false;
  }

  const accountEmailsMap: { [key: string]: boolean } = workspaceAccounts.reduce(
    (acc, curr) => ({ ...acc, [curr.email]: true }),
    {},
  );

  return invitation.recipients.some(
    recipient => accountEmailsMap[recipient.email],
  );
};
export const getInvitationMatchingWorkspaceAccount = (
  workspaceAccounts: AccountApiType[],
  invitation: AccountInvitationApiType | null | undefined,
): AccountApiType | null => {
  if (!invitation || !workspaceAccounts) {
    return null;
  }

  const recipientEmailsMap: {
    [key: string]: boolean;
  } = invitation.recipients.reduce(
    (acc, curr) => ({ ...acc, [curr.email]: true }),
    {},
  );

  return (
    workspaceAccounts.find(account => recipientEmailsMap[account.email]) || null
  );
};
