import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StyledChatMessageThreadSydebar,
  StyledChatMessageThreadSydebarForm,
  StyledChatNoThreadMessagesStyled,
  ThreadMessageLoader,
} from '../ChatMessageThread.styled';
import { ChatTranslation } from '../../../../i18n';
import { ChatItemType, ChatMessageAssetApiType } from '../../../../Chat.types';
import { useChatItems } from '../../ConversationView.hooks';
import { ChatMessageThreadItem } from '../ChatMessageThreadItem';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { useMobile } from '../../../../../../shared/hooks';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { ChatMessageThreadSidebarContext } from './ChatMessageThreadSidebar.context';
import { MessageForm, MessageFormPropsControls } from '../../MessageForm';
import { NoMessagesMessage } from '../../MessagesList';
import { ChatMessageThreadSidebarHeader } from './ChatMessageThreadSidebarHeader';
import { Spinner } from '../../../../../../shared/components/Spinner';
import { highlightChatMessage } from '../../ChatMessage/ChatMessage.utils';
import { getSearchMessageIndex } from '../../../../Chat.utils';
import {
  getThreadMessageNode,
  makeChatMessageThreadDomId,
} from '../ChatMessageThread.utils';
import {
  SCROLL_TO_MESSAGE_AND_HIGHLIGHT_TIMEOUT,
  SCROLL_TO_MESSAGE_VIRTUOSO_TIMEOUT,
} from './ChatMessageThreadSidebar.constants';
import { ConversationDropZone } from '../../ConversationDropZone';
import { SegmentType, useGetCurrentSegment } from '../../../../../Segment';
import { ChatMessageThreadSiderSpacerWithCounter } from './ChatMessageThreadSiderSpacerWithCounter';

// Artificially increase the viewport size, causing items to be rendered before outside of the viewport.
// The property causes the component to render more items than the necessary,
// but it's required to fix thread scroll jumping issue (NODESKMVP-8866)
const INCREASE_THREAD_SCROLL_VIEWPORT_BY = 500;

interface ChatMessageThreadSidebarProps {
  conversationWidth: number;
}

export const ChatMessageThreadSidebar: FC<ChatMessageThreadSidebarProps> = memo(
  ({ conversationWidth }) => {
    const isMobile = useMobile();
    const {
      threadMessages,
      threadSidebarIsOpen,
      conversation,
      chatMessageId,
      loading,
      scrollToMessage,
      setCurrentConversationWidth,
      removeHightlight,
    } = useContext(ChatMessageThreadSidebarContext);
    const messageFormRef = useRef<MessageFormPropsControls>(null);
    const virtuosoRef = useRef<VirtuosoHandle | null>(null);
    const chatMessageThreadSydebarRef = useRef<HTMLDivElement | null>(null);
    const chatItems = useChatItems(threadMessages);
    const { createChatMessage } = useChatMessageRepository();

    const currentSegment = useGetCurrentSegment();
    const [dragAttachments, onFilesDropped] = useState<File[] | undefined>();

    const clearDropZoneState = useCallback(() => {
      onFilesDropped(undefined);
    }, []);

    const hasNoMessages = chatItems.data.length === 0 && !loading;

    const scrollToLastMessage = useCallback(() => {
      virtuosoRef.current?.scrollIntoView({
        index: chatItems.data?.length + 1,
        align: 'end',
      });
    }, [chatItems.data?.length]);

    const handleCreateChatMessage = useCallback(
      async (
        value: string,
        parentChatMessageId?: string | null,
        assets?: ChatMessageAssetApiType[],
      ) =>
        await createChatMessage(
          value,
          chatMessageId,
          assets,
          conversation?.id,
        ).then(() => {
          scrollToLastMessage();
        }),
      [createChatMessage, chatMessageId, conversation?.id, scrollToLastMessage],
    );

    useEffect(() => {
      setCurrentConversationWidth(conversationWidth);
    }, [conversationWidth, setCurrentConversationWidth]);

    const chatRowRenderer = useCallback(
      (index: number, chatItem: ChatItemType) => (
        <>
          <ChatMessageThreadItem
            chatItem={chatItem}
            showNone={false}
            isMobile={isMobile}
            replyFormVisible={false}
            withReaction={false}
          />

          {index === 1 && threadMessages.length > 1 ? (
            <ChatMessageThreadSiderSpacerWithCounter
              count={threadMessages.length - 1}
            />
          ) : null}
        </>
      ),
      [isMobile, threadMessages],
    );

    const scrollToMessageAndHightLight = useCallback(
      (scrollToThreadMessageId: string) => {
        if (!virtuosoRef.current) {
          return;
        }

        const searchMessageIndex = getSearchMessageIndex(
          chatItems.data,
          scrollToThreadMessageId,
        );

        if (!searchMessageIndex || searchMessageIndex < 0) {
          return;
        }

        setTimeout(() => {
          virtuosoRef.current?.scrollToIndex({
            index: searchMessageIndex,
            align: 'center',
          });
        }, SCROLL_TO_MESSAGE_VIRTUOSO_TIMEOUT);

        setTimeout(() => {
          const messageNode = getThreadMessageNode(
            makeChatMessageThreadDomId(scrollToThreadMessageId),
          );

          if (!messageNode) {
            return;
          }

          highlightChatMessage(messageNode);
          removeHightlight();
        }, SCROLL_TO_MESSAGE_AND_HIGHLIGHT_TIMEOUT);
      },
      [chatItems.data, removeHightlight],
    );

    useEffect(() => {
      if (scrollToMessage?.length && chatMessageId) {
        scrollToMessageAndHightLight(scrollToMessage);
      }
    }, [scrollToMessageAndHightLight, scrollToMessage, chatMessageId]);

    useEffect(() => {
      messageFormRef.current?.focus();
    }, [conversation?.id]);

    useEffect(
      () => () => {
        virtuosoRef.current = null;
      },
      [conversation?.id],
    );

    const handleFollowOutput = useCallback(
      (isAtBottom: boolean) => (isAtBottom ? 'auto' : false),
      [],
    );

    if (!threadSidebarIsOpen || !conversation?.id) {
      return null;
    }

    const messageFormKey = `/chat-message-thread-sidebar-form-${conversation.id}`;

    return (
      <StyledChatMessageThreadSydebar
        ref={chatMessageThreadSydebarRef}
        isMobile={isMobile}
        key={chatMessageId}
        currentConversationWidth={conversationWidth}
        isDesktopSegment={currentSegment === SegmentType.DESKTOPS}
        data-testid="chat-message-thread-sidebar">
        <ChatMessageThreadSidebarHeader />

        <ConversationDropZone
          width={chatMessageThreadSydebarRef?.current?.offsetWidth || 0}
          height={chatMessageThreadSydebarRef?.current?.offsetHeight || 0}
          onFilesDropped={onFilesDropped}
          multiple
        />

        {loading && (
          <ThreadMessageLoader>
            <Spinner />
          </ThreadMessageLoader>
        )}

        <Virtuoso
          data-testid="thread-message-list"
          ref={virtuosoRef}
          initialTopMostItemIndex={0}
          increaseViewportBy={INCREASE_THREAD_SCROLL_VIEWPORT_BY}
          style={{
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          data={chatItems.data}
          components={{
            Footer: () =>
              hasNoMessages ? (
                <StyledChatNoThreadMessagesStyled>
                  <FormattedMessage
                    tagName={NoMessagesMessage}
                    id={ChatTranslation.noMessagesInChat}
                  />
                </StyledChatNoThreadMessagesStyled>
              ) : null,
          }}
          itemContent={chatRowRenderer}
          followOutput={handleFollowOutput}
        />

        <StyledChatMessageThreadSydebarForm data-testid="thread-footer">
          <MessageForm
            ref={messageFormRef}
            key={messageFormKey}
            formId={messageFormKey}
            conversation={conversation}
            onSubmit={handleCreateChatMessage}
            canSaveDraft={false}
            dragAttachments={dragAttachments}
            clearDropZoneState={clearDropZoneState}
            draftLoaded={true}
            allowAttachments={true}
            formDisabled={loading}
            typingDisabled={true}
          />
        </StyledChatMessageThreadSydebarForm>
      </StyledChatMessageThreadSydebar>
    );
  },
);
