import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { VaultApiType } from '../../Encryption.types';
import {
  ExpandedVaultsType,
  VaultsListSections,
} from './VaultsList/VaultsList.types';
import {
  appPasswordsFilter,
  appPasswordsSort,
  groupPasswordsFilter,
  otherPasswordsFilter,
  otherPasswordsSort,
} from './PasswordsSwitcher.utils';
import { VAULTS_LIST_SEARCH_BAR } from '../../Encryption.constants';
import { PASSWORDS_VAULT_PATHNAME } from '../../../Desktop/Desktop.constants';

export const useIsVaultSingleViewOpen = (pathname?: string) => {
  return useMemo(() => {
    if (!pathname) {
      return false;
    }

    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);

    if (!match) {
      return false;
    }

    const { vaultId } = match.params;
    return !!vaultId;
  }, [pathname]);
};

export interface VaultsCollapsedSectionsProps {
  app: boolean;
  group: boolean;
  other: boolean;
}

export interface VirtualisedVaultsProps {
  virtuosoVaults: ExpandedVaultsType[];
  collapsedSections: VaultsCollapsedSectionsProps;
}

export const useVirtualisedVaults = (
  vaults: VaultApiType[],
  sectionCollapsed: VaultsListSections[],
): VirtualisedVaultsProps => {
  return useMemo(() => {
    const collapsedSections: VaultsCollapsedSectionsProps = {
      group: sectionCollapsed.includes(VaultsListSections.group),
      app: sectionCollapsed.includes(VaultsListSections.app),
      other: sectionCollapsed.includes(VaultsListSections.other),
    };

    const filteredGroupVaults = collapsedSections.group
      ? []
      : vaults.filter(groupPasswordsFilter).sort(appPasswordsSort);

    const filteredAppsVaults = collapsedSections.app
      ? []
      : vaults.filter(appPasswordsFilter).sort(appPasswordsSort);

    const filteredManualVaults = collapsedSections.other
      ? []
      : vaults.filter(otherPasswordsFilter).sort(otherPasswordsSort);

    return {
      collapsedSections,
      virtuosoVaults: [
        VAULTS_LIST_SEARCH_BAR, // Identifier for search bar, on the basis of which the search is embedded in the list
        VaultsListSections.group,
        ...filteredGroupVaults,
        VaultsListSections.app,
        ...filteredAppsVaults,
        VaultsListSections.other,
        ...filteredManualVaults,
      ].filter(item => item),
    };
  }, [sectionCollapsed, vaults]);
};
