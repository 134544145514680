import React, { FC, memo, useContext } from 'react';
import { ChatMessageThreadContext } from './ChatMessageThread.context';
import { ChatMessageThreadViewStyled } from './ChatMessageThread.styled';
import { useOpenThreadSidebar } from './ChatMessageThreadSidebar/ChatMessageThreadSidebar.hooks';
import { ChatMessageThreadViewAuthors } from './ChatMessageThreadViewAuthors';
import { ChatMessageThreadViewCount } from './ChatMessageThreadViewCount';

export const ChatMessageThreadView: FC = memo(() => {
  const { chatMessage } = useContext(ChatMessageThreadContext);
  const { openThreadSidebar } = useOpenThreadSidebar();

  if (!chatMessage?.id || chatMessage?.threadMessagesCount === 0) {
    return null;
  }

  return (
    <ChatMessageThreadViewStyled
      onClick={() =>
        openThreadSidebar(chatMessage.id, chatMessage.conversationId)
      }
      data-testid="chat-message-thread-view-panel"
      data-entityid={chatMessage.id}>
      <ChatMessageThreadViewAuthors />
      <ChatMessageThreadViewCount />
    </ChatMessageThreadViewStyled>
  );
});
