import React, { FC, useMemo } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { UserTranslation } from '../../i18n';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../User.constants';
import { ButtonSize } from '../../../../shared/components/Button/Button';
import { FormInput } from '../../../../shared/components/Input';
import { AddIcon } from '../../../../shared/icons';
import {
  AddUserButton,
  FormButtonGroup,
  FormFields,
  FormFieldsRow,
} from './InviteUsers.styled';
import { InviteUsersFormFields } from '../../User.types';
import { FormBrowserSelect } from '../../../../shared/components/Select';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { Translation } from '../../../Intl/i18n';
import { InviteUsersFormControls } from './InviteUsers.formControls';
import { ExternalInviteUsers } from './ExternalInviteUsers';
import { useNativeWrapper } from '../../../NativeWrapper';
import { getShortId } from '../../../../shared/utils/id';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';

const INVITE_FIELDS_LIMIT = 10;

export interface InviteUsersFormValues {
  users: InviteUsersFormFields[];
}

export interface InviteUsersFormProps {
  displayGroupSelect?: boolean;
  inviteUsersButtonText?: Translation;
  onSubmit: (values: InviteUsersFormValues) => void;
  onSkip?: () => void;
}

const InviteUsersFormSchema: Yup.Schema<InviteUsersFormValues> =
  Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email(UserTranslation.inviteUsersFormEmailInvalid),
        groupIdentifier: Yup.string(),
      }),
    ),
  });

export const InviteUsersForm: FC<InviteUsersFormProps> = ({
  inviteUsersButtonText,
  onSubmit,
  onSkip,
  displayGroupSelect,
}) => {
  const { formatMessage } = useIntl();
  const { workspace } = useCurrentWorkspace();

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const { account: currentAccount } = useCurrentAccount();
  const currentAccountWorkspaceInfo = useMemo(() => {
    return currentAccount?.workspaceInfo?.find(
      info => info.id === getShortId(workspace.id),
    );
  }, [currentAccount, workspace.id]);
  const currentAccountGroupIdentifier = currentAccountWorkspaceInfo?.role;

  return (
    <Formik
      initialValues={{
        users: [
          {
            email: '',
            groupIdentifier: GroupIdentity.MEMBER,
            type: '',
          },
          {
            email: '',
            groupIdentifier: GroupIdentity.MEMBER,
            type: '',
          },
          {
            email: '',
            groupIdentifier: GroupIdentity.MEMBER,
            type: '',
          },
        ],
      }}
      validationSchema={InviteUsersFormSchema}
      onSubmit={onSubmit}>
      {({ values, isSubmitting, setFieldValue }) => (
        <>
          <Form data-testid="invite-users-form">
            <FieldArray
              name="users"
              render={({ push, remove }: FieldArrayRenderProps) => (
                <>
                  {!isNativeWrapperAvailable && (
                    <ExternalInviteUsers
                      users={values.users}
                      setFormData={setFieldValue}
                      removeContact={remove}
                    />
                  )}
                  <FormFields data-testid="invite-users-panel">
                    {values?.users?.map(
                      (user: InviteUsersFormFields, index: number) =>
                        user.type !== 'external-user' && (
                          <FormFieldsRow
                            className={user.type}
                            key={`users.${index}.email`}
                            data-testid="invite-user-panel">
                            <Field
                              key={index}
                              name={`users.${index}.email`}
                              type="email"
                              autoComplete="list"
                              placeholder={formatMessage({
                                id: UserTranslation.inviteUsersEmailPlaceholder,
                              })}
                              component={FormInput}
                              data-testid="user-email"
                            />
                            {displayGroupSelect && (
                              <Field
                                name={`users.${index}.groupIdentifier`}
                                component={FormBrowserSelect}
                                data-testid="user-group">
                                <option value={GroupIdentity.MEMBER}>
                                  {formatMessage({
                                    id: UserTranslation[GroupIdentity.MEMBER],
                                  })}
                                </option>
                                {(currentAccountGroupIdentifier ===
                                  GroupIdentity.OWNER ||
                                  currentAccountGroupIdentifier ===
                                    GroupIdentity.ADMIN) && (
                                  <option value={GroupIdentity.ADMIN}>
                                    {formatMessage({
                                      id: UserTranslation[GroupIdentity.ADMIN],
                                    })}
                                  </option>
                                )}
                              </Field>
                            )}
                          </FormFieldsRow>
                        ),
                    )}
                  </FormFields>

                  <FormButtonGroup>
                    {values.users.length < INVITE_FIELDS_LIMIT && (
                      <AddUserButton
                        type="button"
                        iconFirst
                        size={ButtonSize.md}
                        icon={AddIcon}
                        data-testid="add-more"
                        onClick={() =>
                          push({
                            email: '',
                            groupIdentifier: GroupIdentity.MEMBER,
                          })
                        }>
                        <FormattedMessage
                          id={UserTranslation.inviteUsersAddButton}
                        />
                      </AddUserButton>
                    )}
                  </FormButtonGroup>

                  <InviteUsersFormControls
                    users={values.users}
                    isSubmitting={isSubmitting}
                    inviteUsersButtonText={inviteUsersButtonText}
                    onSkip={onSkip}
                  />
                </>
              )}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};
