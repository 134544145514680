export enum ChatTranslation {
  // various labels
  labelsYou = 'chat_labelsYou',
  labelsSystem = 'chat_labelsSystem',
  labelDeletedAccount = 'chat_labelDeletedAccount',

  // switcher
  switcherHeader = 'chat_switcherHeader',

  // conversations list
  switcherListItemPinButton = 'chat_switcherListItemPinButton',
  switcherListItemMarkAllRead = 'chat_switcherListItemMarkAllRead',
  switcherListItemOptionsTooltip = 'chat_switcherListItemOptionsTooltip',
  hideConversation = 'chat_hideConversation',

  // message form
  newMessageFormInputPlaceholder = 'chat_newMessageFormInputPlaceholder',
  newMessageFormSendButton = 'chat_newMessageFormSendButton',
  newMessageFormPickEmojiButton = 'chat_newMessageFormPickEmojiButton',
  newMessageFormInsertMentionButton = 'chat_newMessageFormInsertMentionButton',
  newMessageFormInputEditPlaceholder = 'chat_newMessageFormInputEditPlaceholder',
  newMessageFormInputReplyPlaceholder = 'chat_newMessageFormInputReplyPlaceholder',
  newMessageFormInputLinkChatPlaceholder = 'chat_newMessageFormInputLinkChatPlaceholder',
  newMessageFormCancelButton = 'chat_newMessageFormCancelButton',
  newMessageFormSaveButton = 'chat_newMessageFormSaveButton',
  newMessageFormFileUploadAccountLimitReached = 'chat_newMessageFormFileUploadAccountLimitReached',
  newMessageFormMessageNotSentErrorMessage = 'chat_newMessageFormMessageNotSentErrorMessage',

  // chat avatar
  chatAvatarAlt = 'chat_chatAvatarAlt',

  // account popover
  accountPopoverMessageButton = 'chat_accountPopoverMessageButton',
  accountPopoverCallButton = 'chat_accountPopoverCallButton',
  accountPopoverVideoCallButton = 'chat_accountPopoverVideoCallButton',
  accountPopoverEmailLabel = 'chat_accountPopoverEmailLabel',
  accountPopoverPhoneLabel = 'chat_accountPopoverPhoneLabel',
  accountPopoverAddPhoneNumberText = 'chat_accountPopoverAddPhoneNumberText',
  accountPopoverLocalTimeLabel = 'chat_accountPopoverLocalTimeLabel',
  accountPopoverCompanyNameLabel = 'chat_accountPopoverCompanyNameLabel',
  accountPopoverAddCompanyText = 'chat_accountPopoverAddCompanyText',
  accountPopoverInvitedByText = 'chat_accountPopoverInvitedByText',
  accountPopoverContactDisabledFeature = 'chat_accountPopoverContactDisabledFeature',

  // sidebar
  sidebarTitle = 'chat_sidebarTitle',
  sidebarChatParticipants = 'chat_sidebarChatParticipants',
  sidebarMeetingParticipants = 'chat_sidebarMeetingParticipants',
  sidebarDesktopAccounts = 'chat_sidebarDesktopAccounts',
  sidebarWorkspaceMembers = 'chat_sidebarWorkspaceMembers',
  sidebarWorkspaceGuests = 'chat_sidebarWorkspaceGuests',
  sidebarGuest = 'chat_sidebarGuest',
  closeMembersButton = 'chat_closeMembersButton',
  openMembersButton = 'chat_openMembersButton',

  //create chat
  createConversationModalHeader = 'chat_createConversationModalHeader',
  createConversationErrorMessage = 'chat_createConversationErrorMessage',
  createConversationButton = 'chat_createConversationButton',
  createConversationTitleFieldPlaceholder = 'chat_createConversationTitleFieldPlaceholder',
  cancelCreateConversationButton = 'chat_cancelCreateConversationButton',
  createConversationImportantMessage = 'chat_createConversationImportantMessage',
  createConversationImportantMessageProFeatureLabel = 'chat_createConversationImportantMessageProFeatureLabel',
  recentContactsTitle = 'chat_recentContactsTitle',
  conversationSelectPlaceholder = 'chat_conversationSelectPlaceholder',
  conversationSelectEmptyText = 'chat_conversationSelectEmptyText',
  conversationSelectInviteText = 'chat_conversationSelectInviteText',
  conversationUsersMenuRemoveFromChat = 'chat_conversationUsersMenuRemoveFromChat',
  conversationUsersMenuRemoveConfirmation = 'chat_conversationUsersMenuRemoveConfirmation',
  conversationUsersMenuRevokeInvitation = 'chat_conversationUsersMenuRevokeInvitation',
  conversationUsersMenuCopyInviteLink = 'chat_conversationUsersMenuCopyInviteLink',
  conversationUsersMenuCopyInviteLinkSuccessMessage = 'chat_conversationUsersMenuCopyInviteLinkSuccessMessage',
  conversationUsersPendingLabel = 'chat_conversationUsersPendingLabel',
  conversationUsersCloseButton = 'chat_conversationUsersCloseButton',

  // chat messages
  chatConferenceMessageCallStarted = 'chat_chatConferenceMessageCallStarted',
  chatConferenceMessageCallEnded = 'chat_chatConferenceMessageCallEnded',
  chatConferenceMessageJoin = 'chat_chatConferenceMessageJoin',
  chatConferenceMessageParticipantsCounter = 'chat_chatConferenceMessageParticipantsCounter',
  chatConferenceMessageNoOneInTheCall = 'chat_chatConferenceMessageNoOneInTheCall',

  recentContactsWarning = 'chat_recentContactsWarning',

  searchLinksResultNoFound = 'chat_searchLinksResultNoFound',

  conferenceParticipantsTitle = 'chat_conferenceParticipantsTitle',

  noMembersMessage = 'chat_noMembersMessage',
  filterMembersPlaceholder = 'chat_filterMembersPlaceholder',
  filterMembersAddUserTooltip = 'chat_filterMembersAddUserTooltip',

  messageMenuItemEdit = 'chat_messageMenuItemEdit',
  messageMenuItemDelete = 'chat_messageMenuItemDelete',
  messageMenuItemCopyLink = 'chat_messageMenuItemCopyLink',

  linksMenuRemovePreview = 'chat_linksMenuRemovePreview',

  messageAssetMenuItemDownload = 'chat_messageAssetMenuItemDownload',
  messageAssetMenuItemDownloadOriginal = 'chat_messageAssetMenuItemDownloadOriginal',
  messageAssetMenuItemDownloadCompressed = 'chat_messageAssetMenuItemDownloadCompressed',
  messageAssetMenuItemDelete = 'chat_messageAssetMenuItemDelete',

  messageDeleteConfirmation = 'chat_messageDeleteConfirmation',
  messageAssetDeleteConfirmation = 'chat_messageAssetDeleteConfirmation',

  noMessagesInChat = 'chat_noMessagesInChat',

  messageModifiedLabel = 'chat_messageModifiedLabel',

  chatDateDelimiterToday = 'chat_chatDateDelimiterToday',

  chatEmptyState = 'chat_chatEmptyState',

  // group chat menu
  groupChatMenuManageUsers = 'chat_groupChatMenuManageUsers',
  groupChatMenuRenameChat = 'chat_groupChatMenuRenameChat',
  groupChatMenuLeaveChat = 'chat_groupChatMenuLeaveChat',
  groupChatMenuDeleteChat = 'chat_groupChatMenuDeleteChat',

  // rename chat
  renameChatModalTitle = 'chat_renameChatModalTitle',
  chatFormTitleFieldLabel = 'chat_chatFormTitleFieldLabel',
  chatFormTitleMaximumSymbols = 'chat_chatFormTitleMaximumSymbols',
  chatFormTitleFieldPlaceholder = 'chat_chatFormTitleFieldPlaceholder',
  chatFormSubmitButtonLabel = 'chat_chatFormSubmitButtonLabel',

  // manage users
  manageUsersModalTitle = 'chat_manageUsersModalTitle',
  manageUsersSubmitButtonLabel = 'chat_manageUsersSubmitButtonLabel',

  // leave chat
  leaveChatModalTitle = 'chat_leaveChatModalTitle',
  leaveChatModalSubmitButtonLabel = 'chat_leaveChatModalSubmitButtonLabel',
  leaveChatModalCancelButtonLabel = 'chat_leaveChathatModalCancelButtonLabel',
  leaveChatModalCaseWithoutChatName = 'chat_leaveChatModalCaseWithoutChatName',

  // delete chat
  removeChatModalTitle = 'chat_removeChatModalTitle',
  removeChatModalSubmitButtonLabel = 'chat_removeChatModalSubmitButtonLabel',
  removeChatModalCancelButtonLabel = 'chat_removeChatModalCancelButtonLabel',
  removeChatModalCaseWithoutChatName = 'chat_removeChatModalCaseWithoutChatName',

  //manage user
  manageUserButtonLabel = 'chat_manageUserButtonLabel',

  // attachment
  attachmentRemoveButtonLabel = 'chat_attachmentRemoveButtonLabel',
  attachmentImagePreviewAlt = 'chat_attachmentImagePreviewAlt',
  attachmentUploadButtonLabel = 'chat_attachmentUploadButtonLabel',

  // reactions
  reactionsCurrentUserOnly = 'chat_reactionsCurrentUserOnly',
  reactionsOtherUsers = 'chat_reactionsOtherUsers',
  reactionsCurrentUserSuffix = 'chat_reactionsCurrentUserSuffix',

  // threads
  threadModalHeader = 'chat_threadModalHeader',
  expandThreadButton = 'chat_expandThreadButton',
  expandMoreThreadButton = 'chat_expandMoreThreadButton',
  collapseThreadButton = 'chat_collapseThreadButton',
  replyThreadButton = 'chat_replyThreadButton',
  threadCount = 'chat_threadCount',
  lastReplyAt = 'chat_lastReplyAt',
  viewThread = 'chat_viewThread',

  // people panel
  peoplePanelInACallBadge = 'chat_peoplePanelInACall',

  // tooltips
  tooltipMentionIcon = 'chat_tooltipMentionIcon',
  tooltipEmojiIcon = 'chat_tooltipEmojiIcon',
  tooltipAttachFileIcon = 'chat_tooltipAttachFileIcon',
  tooltipSendMessageIcon = 'chat_tooltipSendMessageIcon',
  tooltipDisabledInputMessageForSuspendedUser = 'chat_tooltipDisabledInputMessageForSuspendedUser',
  tooltipGoToLastMessage = 'chat_tooltipGoToLastMessage',
  tooltipChatAudioCallButton = 'chat_tooltipChatAudioCallButton',
  tooltipChatVideoCallButton = 'chat_tooltipChatVideoCallButton',
  tooltipReplyInTreadButton = 'chat_tooltipReplyInTreadButton',
  tooltipMoreActionsButton = 'chat_tooltipMoreActionsButton',
  tooltipAddReactionButton = 'chat_tooltipAddReactionButton',
  tooltipCreateChatButton = 'chat_tooltipCreateChatButton',
  tooltipPinChatButton = 'chat_tooltipPinChatButton',
  tooltipUnpinChatButton = 'chat_tooltipUnpinChatButton',
  tooltipMemberDetails = 'chat_tooltipMemberDetails',
  tooltipGoToDesktopButton = 'chat_tooltipGoToDesktopButton',

  // last chat message attachment
  chatAttachment = 'chat_attachment',
  chatAttachments = 'chat_attachments',

  // last chat message call type
  chatCallTypeVideo = 'chat_chatCallTypeVideo',
  chatCallTypeAudio = 'chat_chatCallTypeAudio',

  // message asset
  chatMessageAssetAltText = 'chat_chatMessageAssetAltText',
  conversationListPinned = 'chat_conversationListPinned',
  conversationListIntegrations = 'chat_conversationListIntegrations',
  conversationListChannels = 'chat_conversationListChannels',
  conversationListDirectMessages = 'chat_conversationListDirectMessages',
  conversationFilterPlaceholder = 'chat_conversationFilterPlaceholder',

  appIntegrationTooltip = 'chat_appIntegrationTooltip',
  appIntegrationNoAccessTooltip = 'chat_appIntegrationNoAccessTooltip',

  usersSelectGroupTypeMembers = 'chat_usersSelectGroupTypeMembers',
  usersSelectGroupTypeGuests = 'chat_usersSelectGroupTypeGuests',

  //chat message thread sidebar
  chatMessageSidebarTitle = 'chat_chatMessageSidebarTitle',
  chatMessageSidebarLabelClose = 'chat_chatMessageSidebarLabelClose',
}

export type ChatLocale = {
  [key in ChatTranslation]: string;
};
